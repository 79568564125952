import React, { useEffect, useState } from 'react';
import usePollingMessages from '../usePollingMessages';
import UnreadBadge from './UnreadBadge'; // Import the UnreadBadge component
import { useParams } from 'react-router-dom';

const MessagesSection = ({ openChat }) => {
  const [userData, setUserData] = useState({});
  const [unreadCounts, setUnreadCounts] = useState({});
  const [startedChats, setStartedChats] = useState({}); // Track started chats
  const { idcode } = useParams(); // Get idcode from URL params
  const { messages, loading, error, markMessagesAsRead } = usePollingMessages(idcode);
  const currentUserId = JSON.parse(localStorage.getItem('authData'))?.idcode; // Get the current user's ID

  useEffect(() => {
    const fetchUserData = async (senderId) => {
      try {
        const response = await fetch(`https://hi.irtwitch.ir/api/user/${senderId}`);
        const data = await response.json();
        if (response.ok) {
          setUserData((prevState) => ({
            ...prevState,
            [senderId]: data
          }));
        } else {
          console.error('Failed to fetch user data.');
        }
      } catch (error) {
        console.error('Error fetching user data from the server.', error);
      }
    };

    Object.keys(messages).forEach((senderId) => {
      if (senderId !== currentUserId && !userData[senderId]) {
        fetchUserData(senderId);
      }
    });
  }, [messages, userData, currentUserId]);

  useEffect(() => {
    const newUnreadCounts = {};
    Object.keys(messages).forEach((senderId) => {
      if (senderId !== currentUserId) {
        const unreadMessages = messages[senderId].filter((msg) => !msg.isRead);
        newUnreadCounts[senderId] = unreadMessages.length;
      }
    });
    setUnreadCounts(newUnreadCounts);
  }, [messages, currentUserId]);

  const handleChatOpen = async (senderId) => {
    openChat({ id: senderId, name: userData[senderId] ? userData[senderId].name : 'کاربر' });

    // Update message read status
    markMessagesAsRead(senderId);

    // Set started chat status
    setStartedChats((prevChats) => ({
      ...prevChats,
      [senderId]: true
    }));

    const unreadMessages = messages[senderId].filter((msg) => !msg.isRead);
    const messageIdsToUpdate = unreadMessages.map((msg) => msg.messageId);

    if (messageIdsToUpdate.length === 0) {
      return;
    }

    try {
      for (const messageId of messageIdsToUpdate) {
        await fetch(`https://hi.irtwitch.ir/api/users/comments/${messageId}/read`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      setUnreadCounts((prevUnreadCounts) => ({
        ...prevUnreadCounts,
        [senderId]: 0,
      }));
    } catch (error) {
      console.error('Failed to update message read status.', error);
    }
  };

  if (loading) return <p>در حال بارگذاری...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h2>پیام‌ها</h2>
      {Object.keys(messages)
        .filter((senderId) => senderId !== currentUserId) // Exclude current user's ID
        .map((senderId) => (
          <div key={senderId} className="message-group">
            <h3>
              پیام‌ها با: {userData[senderId] ? userData[senderId].name : 'در حال بارگذاری...'}
            </h3>
            <p>ایمیل: {userData[senderId] ? userData[senderId].email : 'در حال بارگذاری...'}</p>

            <button
              className="btn btn-primary"
              onClick={() => handleChatOpen(senderId)}
            >
              {startedChats[senderId] ? `گفتگو با ${userData[senderId] ? userData[senderId].name : 'کاربر'}` : `شروع گفتگو با ${userData[senderId] ? userData[senderId].name : 'کاربر'}`}
            </button>
            <div style={{ display: 'inline-block', position: 'relative', top: '-20px', left: '10px' }}>
              <UnreadBadge count={unreadCounts[senderId]} /> {/* Using UnreadBadge */}
            </div>
          </div>
        ))}
    </div>
  );
};

export default MessagesSection;
