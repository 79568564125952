import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const API_BASE_URL = "https://hi.irtwitch.ir/api/stores";

const StoreList = () => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await axios.get(API_BASE_URL);
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      setError("خطا در بارگذاری فروشگاه‌ها");
      setLoading(false);
    }
  };

  const deleteStore = async (idcode, storeName) => {
    if (window.confirm(`آیا مطمئن هستید که می‌خواهید فروشگاه "${storeName}" را حذف کنید؟`)) {
      try {
        await axios.delete(`${API_BASE_URL}/delete-store`, {
          data: { idcode, storeName },
        });
        setStores(stores.filter((store) => !(store.idcode === idcode && store.storeName === storeName)));
        alert("فروشگاه با موفقیت حذف شد!");
      } catch (error) {
        alert("خطا در حذف فروشگاه");
      }
    }
  };

  const toggleBlockStore = async (idcode, storeName, currentStatus) => {
    try {
      await axios.put(`${API_BASE_URL}/block-store`, {
        idcode,
        storeName,
        blocked: !currentStatus,
      });
      setStores(
        stores.map((store) =>
          store.idcode === idcode && store.storeName === storeName
            ? { ...store, blocked: !currentStatus }
            : store
        )
      );
      alert(`فروشگاه با موفقیت ${!currentStatus ? "مسدود" : "آزاد"} شد!`);
    } catch (error) {
      alert("خطا در تغییر وضعیت مسدودیت فروشگاه");
    }
  };

  const toggleVerifyStore = async (idcode, storeName, currentStatus) => {
    try {
      await axios.put(`${API_BASE_URL}/verify-store`, {
        idcode,
        storeName,
        blueTick: !currentStatus,
      });
      setStores(
        stores.map((store) =>
          store.idcode === idcode && store.storeName === storeName
            ? { ...store, blueTick: !currentStatus }
            : store
        )
      );
      alert(`فروشگاه با موفقیت ${!currentStatus ? "تایید" : "لغو تایید"} شد!`);
    } catch (error) {
      alert("خطا در تغییر وضعیت تایید فروشگاه");
    }
  };

  if (loading) return <div className="d-flex justify-content-center"><p>در حال بارگذاری...</p></div>;
  if (error) return <div className="alert alert-danger" role="alert">{error}</div>;

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">لیست فروشگاه‌ها</h1>
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>نام فروشگاه</th>
            <th>کد ملی</th>
            <th>مسدودیت</th>
            <th>تیک آبی</th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody>
          {stores.map((store) => (
            <tr key={`${store.idcode}-${store.storeName}`}>
              <td>
                {store.storeName}{" "}
                {store.blueTick && <FontAwesomeIcon icon={faCheckCircle} color="#1da1f2" />}
              </td>
              <td>{store.idcode}</td>
              <td>
                <button
                  className={`btn ${store.blocked ? "btn-danger" : "btn-success"} btn-sm`}
                  onClick={() =>
                    toggleBlockStore(store.idcode, store.storeName, store.blocked)
                  }
                >
                  {store.blocked ? "مسدود" : "آزاد"}
                </button>
              </td>
              <td>
                <button
                  className={`btn ${store.blueTick ? "btn-primary" : "btn-secondary"} btn-sm`}
                  onClick={() =>
                    toggleVerifyStore(store.idcode, store.storeName, store.blueTick)
                  }
                >
                  {store.blueTick ? "تایید شده" : "تایید نشده"}
                </button>
              </td>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteStore(store.idcode, store.storeName)}
                >
                  حذف
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StoreList;
