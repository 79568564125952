import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'; // برای آیکون‌های برند

import '../style/footer.css';
import '../style/responsive/footerR.css'
class Footer extends Component {
  render() {
    return (
      <>
        <div className="pagefoter">
          <div className="footer">
            <div className="icons">
              <FontAwesomeIcon icon={faFacebookF} />
              <FontAwesomeIcon icon={faTwitter} />
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faLinkedinIn} />

            </div>
            <p>تمامی حقوق این سایت متعلق به سازنده آن میباشد.</p>
          </div>
        </div>

      </>
    );
  }
}

export default Footer;
