import React, { useEffect, useState } from 'react';
import Header from "./header";
import Auctions from './auctions';
import "../style/home.css";
import Footer from "./footer";
import StoreList from "./storeList";
import DateAuctions from './datesAuctions';

const Home = () => {
  const [user, setUser] = useState(null);


  useEffect(() => {
    const authData = localStorage.getItem('authData');
    if (authData) {
      try {
        const parsedData = JSON.parse(authData);
        setUser(parsedData);
      } catch (error) {
        console.error("خطا در تجزیه داده‌های احراز هویت:", error);
      }
    }
    // اگر داده‌های احراز هویت وجود نداشته باشد، همچنان Home را نمایش می‌دهیم
  }, []);

  // اگر کاربر وجود نداشته باشد، می‌توانید یک پیام عمومی نمایش دهید یا صفحه Home را با اطلاعات عمومی بارگذاری کنید
  return (
    <div>
      {user ? (
        <div>
          {/* <h1>خوش آمدید، {user.idcode}</h1> */}
          <Header />
          <div className="titelAuctions">فروشگاه هــای موجــود</div>
          <StoreList />
          <div className="titelAuctions">مزایــده هــای موجــود</div>
          <Auctions />
          <div className="titelAuctions"> تاریخ مزایــده هـا </div>
          <DateAuctions />
          <Footer />
        </div>
      ) : (
        <div>
          <Header />
          <div className="titelAuctions">فروشگاه هــای موجــود</div>
          <StoreList />
          <div className="titelAuctions">مزایــده هــای موجــود</div>
          <Auctions />
          <div className="titelAuctions"> تاریخ مزایــده هـا </div>
          <DateAuctions />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
