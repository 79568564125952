import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Captcha from '../Components/captcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import "../style/login.css";

class Login extends Component {
  state = {
    showRegister: false,
    passwordVisible: false,
    captchaInput: '',
    captchaVerified: false,
    name: '',
    family: '',
    email: '',
    idcode: '',
    password: '',
    redirectToProfile: false,
    redirectToLogin: false,
    isAdmin: false,
    isBlocked: false // حالت جدید برای بررسی اینکه آیا کاربر مسدود شده است یا خیر
  };

  componentDidMount() {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (authData && authData.idcode) {
      this.setState({
        redirectToProfile: authData.idcode,
        isAdmin: authData.isAdmin,
        isBlocked: authData.isBlocked // بررسی وضعیت مسدود بودن از localStorage
      });
    }
  }

  toggleRegister = () => {
    this.setState({ showRegister: !this.state.showRegister });
  };

  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  handleCaptchaChange = (value) => {
    this.setState({ captchaInput: value, captchaVerified: !!value });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogin = async () => {
    const { idcode, password, captchaVerified } = this.state;

    if (!idcode || !password) {
      toast.error('لطفاً کد ملی و رمز عبور را وارد کنید.');
      return;
    }

    if (!captchaVerified) {
      toast.error('کد امنیتی را وارد کنید.');
      return;
    }

    try {
      const response = await fetch('https://hi.irtwitch.ir/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idcode, password }),
      });
      const data = await response.json();

      if (data.error) {
        toast.error(data.error);
      } else if (data.is_blocked) {
        toast.error('حساب شما مسدود شده است.');
        this.setState({ isBlocked: true }); // به‌روزرسانی وضعیت مسدود بودن در state
        localStorage.removeItem('authData'); // حذف اطلاعات کاربری از localStorage
      } else {
        toast.success('ورود موفقیت‌آمیز بود!');
        localStorage.setItem('authData', JSON.stringify({ idcode, isAdmin: data.is_admin, isBlocked: data.is_blocked }));
        this.props.onLogin(); // بروزرسانی وضعیت ورود در App
        this.setState({
          redirectToProfile: idcode,
          isAdmin: data.is_admin,
          isBlocked: data.is_blocked
        });
      }
    } catch (error) {
      toast.error('خطایی رخ داد. لطفاً دوباره تلاش کنید.');
    }
  };


  handleRegister = async () => {
    const { name, family, email, idcode, password, captchaVerified } = this.state;

    if (!name || !family || !email || !idcode || !password) {
      toast.error('لطفاً تمام فیلدها را پر کنید.');
      return;
    }

    if (!captchaVerified) {
      toast.error('کد امنیتی را وارد کنید.');
      return;
    }

    try {
      const response = await fetch('https://hi.irtwitch.ir/api/users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, family, email, idcode, password }),
      });
      const data = await response.json();

      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success('ثبت نام با موفقیت انجام شد!');
        this.setState({ showRegister: false });
      }
    } catch (error) {
      toast.error('خطایی رخ داد. لطفاً دوباره تلاش کنید.');
    }
  };

  render() {
    const { showRegister, passwordVisible, redirectToProfile, redirectToLogin, isAdmin, isBlocked } = this.state;

    if (redirectToProfile) {
      if (isBlocked) {
        // اگر کاربر مسدود شده باشد، به صفحه ورود هدایت می‌شود و پیام خطا نمایش داده می‌شود
        return <Navigate to="/login" />;
      }
      if (isAdmin) {
        return <Navigate to={`/management/${redirectToProfile}`} />;
      }
      return <Navigate to={`/profile/${redirectToProfile}`} />;
    }

    if (redirectToLogin) {
      return <Navigate to="/login" />;
    }

    return (
      <>
        <Toaster />
        <div className='loginpage'>
          <div className={`loginAndRegister ${showRegister ? 'show-register' : ''}`}>
            <div className="login">
              <p>فرم ورود</p>
              <input
                type="text"
                name="idcode"
                placeholder="کد ملی"
                onChange={this.handleInputChange}
              />
              <div className="password-container">
                <span className="eye-icon" onClick={this.togglePasswordVisibility}>
                  <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                </span>
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="رمز عبور"
                  onChange={this.handleInputChange}
                />
              </div>
              <Captcha onChange={this.handleCaptchaChange} />
              <button className='btn_left btn btn-success m-2' onClick={this.handleLogin}>ورود</button>
              <button className='btn_right btn btn-primary m-2' onClick={this.toggleRegister}>ثبت نام</button>
            </div>
            <div className='register'>
              <p>فرم ثبت نام</p>
              <input
                type="text"
                name="name"
                placeholder="نام"
                onChange={this.handleInputChange}
              />
              <input
                type="text"
                name="family"
                placeholder="نام خانوادگی"
                onChange={this.handleInputChange}
              />
              <input
                type="email"
                name="email"
                placeholder="ایمیل"
                onChange={this.handleInputChange}
              />
              <input
                type="text"
                name="idcode"
                placeholder="کد ملی"
                onChange={this.handleInputChange}
              />
              <div className="password-container">
                <span className="eye-icon" onClick={this.togglePasswordVisibility}>
                  <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                </span>
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="رمز عبور"
                  onChange={this.handleInputChange}
                />
              </div>
              <Captcha onChange={this.handleCaptchaChange} />
              <button className='btn_left btn btn-primary m-2' onClick={this.handleRegister}>ثبت نام</button>
              <button className='btn_right btn btn-secondary m-2' onClick={this.toggleRegister}>بازگشت</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
