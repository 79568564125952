// src/components/EndAuctionButton.js
import React from "react";
import axios from "axios";

const EndAuctionButton = ({ auction, user, navigate, setAuctionEnded }) => {
  const handleEndAuction = async () => {
    if (user && auction) {
      try {
        await axios.patch(
          `https://hi.irtwitch.ir/api/auctions/${auction.id}/is_start`,
          { is_start: false }
        );
        setAuctionEnded(true);
        setTimeout(() => {
          navigate(`/profile/${user.idcode}`);
        }, 2000);
      } catch (error) {
        console.error("خطا در پایان مزایده:", error);
        alert("خطا در پایان مزایده");
      }
    }
  };

  return (
    <button className="btndanger" onClick={handleEndAuction}>
      بستن مزایده
    </button>
  );
};

export default EndAuctionButton;
