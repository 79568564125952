import React, { useState } from 'react';
import '../style/captcha.css'; // فایل CSS برای استایل‌های کپچا


const Captcha = ({ onChange }) => {
  // تابع برای تولید کد کپچا
  const generateCaptcha = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  // State برای نگهداری کپچا و ورودی کاربر
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [inputValue, setInputValue] = useState('');
  const [captchaError, setCaptchaError] = useState('');

  // تابع برای تازه کردن کپچا
  const refreshCaptcha = () => {
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);
    setInputValue('');
    setCaptchaError('');
    onChange('');
  };

  // تابع برای تغییر ورودی کاربر
  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    setInputValue(value);
    if (value === captcha) {
      setCaptchaError(''); // حذف پیام خطا در صورت صحیح بودن
      onChange(value);
    } else {
      setCaptchaError(value ? 'کد امنیتی اشتباه است.' : ''); // نمایش پیام خطا در صورت نادرست بودن
      onChange('');
    }
  };

  return (
    <div className="captcha-container">
      <div className="captcha-display">
        {captcha}
        <button style={{ width: "30px" }} className="refresh-btn" onClick={refreshCaptcha}>⟳</button>
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="کد امنیتی را وارد کنید"
        className="captcha-input"
        style={{ borderRadius: "5px 5px 20px 20px" }}
      />
      {captchaError && <p className="captcha-error">{captchaError}</p>}
    </div>
  );
};

export default Captcha;
