import React, { useEffect, useState } from 'react';
import '../style/header.css';
import '../style/responsive/headerR.css';
import imgnext from '../style/image/left-arrow.png';
import userinterface from "../style/image/user-interface.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faConciergeBell, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BackgroundSwitcher from './BackgroundSwitcher';

const Header = () => {

  const [user, setUser] = useState(null);


  useEffect(() => {
    const authData = localStorage.getItem('authData');
    if (authData) {
      try {
        const parsedData = JSON.parse(authData);
        setUser(parsedData);
      } catch (error) {
        console.error("خطا در تجزیه داده‌های احراز هویت:", error);
      }
    }
    // اگر داده‌های احراز هویت وجود نداشته باشد، همچنان Home را نمایش می‌دهیم
  }, []);

  return (
    <div className="header">

      <div className="part1">
        <div className="part1part"><p align="center">لگو</p></div>
        <div className="part1part middle">
          <nav className="menu">
            <ul>
              <li>
                <BackgroundSwitcher />
              </li>
              <ul>
                <li>
                  <NavLink to="#home" className="menuoptions">
                    <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
                    <span className="text">خانه</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#about" className="menuoptions">
                    <span className="icon"><FontAwesomeIcon icon={faInfoCircle} /></span>
                    <span className="text">درباره&nbsp;ما</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#services" className="menuoptions">
                    <span className="icon"><FontAwesomeIcon icon={faConciergeBell} /></span>
                    <span className="text">سرویس&nbsp;ها</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#contact" className="menuoptions">
                    <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <span className="text">ارتباط&nbsp;با&nbsp;ما</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/login'}>
                    <img
                      src={userinterface}
                      className="userinterface_icon"
                      width={"25px"}
                      alt=""
                      style={{ cursor: "pointer", transition: "all 1s ease" }}
                    />
                  </NavLink>

                </li>
              </ul>
            </ul>
          </nav>
        </div>

        <div className="part1part userinterface_text">
          <NavLink to="/login" style={{ textDecoration: 'none', color: "white" }}>
            {user ? "حساب کاربری" : <p align="center">ورود / ثبت نام</p>}
          </NavLink>
        </div>

      </div>

      <div className="part2">
        <div className="text" align="center">
          <p>مـــــــــــــزایــــــــــده</p>
          <p>مـــــــــــــزایــــــــــده</p>
          <p>مـــــــــــــزایــــــــــده</p>
        </div>
        <div className="img"></div>
      </div>

      <div className="part3">
        <div className="part3part">
          <button className="btn btn-dark btnNext">
            <img src={imgnext} alt="" width={"40px"} style={{ transform: "rotate(180deg)" }} />
          </button>
          <button className="btn btn-dark btnNext">
            <img src={imgnext} alt="" width={"40px"} />
          </button>
        </div>
        <div className="part3part middle">
          <p align="justify">
            مَزایِده (Auction) یک فرآیند خرید و فروش است که در آن کالاها یا خدمات به بالاترین پیشنهاد دهنده فروخته می‌شود. این فرآیند می‌تواند در قالب‌های مختلفی برگزار شود، از جمله مزایده‌های آنلاین، مزایده‌های حضوری، و مزایده‌های تلفنی. در ادامه، توضیحات بیشتری در مورد مزایده‌ها و جنبه‌های مختلف آنها آورده شده است.
          </p>
          <NavLink to="/login"><button className="btn btn-dark w-100">   مزایده خودتو بساز</button></NavLink>
        </div>
        <div className="part3part">
          <button className="btn btn-dark btnNext">
            <img src={imgnext} alt="" width={"40px"} style={{ transform: "rotate(270deg)" }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
