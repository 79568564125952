import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

const usePollingMessages = () => {
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idcode } = useParams(); // دریافت idcode از پارامترهای URL

  const fetchMessages = useCallback(
    async (isInitialLoad = false) => {
      try {
        if (isInitialLoad) setLoading(true);

        const response = await fetch(
          `https://hi.irtwitch.ir/api/users/comments/${idcode}`
        );
        const data = await response.json();

        if (response.ok) {
          if (data.length === 0) {
            setError("پیامی برای شما وجود ندارد.");
            setMessages({});
          } else {
            const grouped = data.reduce((acc, message) => {
              const userKey =
                message.senderId !== idcode
                  ? message.senderId
                  : message.receiverId;

              if (!acc[userKey]) {
                acc[userKey] = [];
              }
              // فقط پیام‌هایی که فرستنده آن کاربر نیست و خوانده نشده‌اند را اضافه می‌کنیم
              if (message.senderId === idcode) {
                // برای پیام‌های ارسالی، وضعیت خوانده شده باید true باشد
                acc[userKey].push({ ...message, isRead: true });
              } else if (!message.isRead) {
                // برای پیام‌های دریافتی که خوانده نشده‌اند
                acc[userKey].push(message);
              }

              return acc;
            }, {});

            setMessages(grouped);
            setError(null); // در صورت دریافت پیام‌ها، خطا را پاک کنید
          }
        } else {
          setError("خطا در دریافت پیام‌ها.");
        }
      } catch (error) {
        setError("خطا در برقراری ارتباط با سرور.");
      } finally {
        if (isInitialLoad) setLoading(false);
      }
    },
    [idcode]
  );

  const markMessagesAsRead = async (senderId) => {
    try {
      await axios.put(
        `https://hi.irtwitch.ir/api/users/comments/${senderId}/read`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setMessages((prevMessages) => {
        const updatedMessages = { ...prevMessages };
        if (updatedMessages[senderId]) {
          updatedMessages[senderId] = updatedMessages[senderId].map((msg) => ({
            ...msg,
            isRead: true,
          }));
        }
        return updatedMessages;
      });
    } catch (error) {
      console.error("Failed to mark messages as read:", error);
    }
  };

  useEffect(() => {
    fetchMessages(true); // فراخوانی تابع با isInitialLoad برابر true برای بار اول

    const interval = setInterval(() => {
      fetchMessages(); // واکشی پیام‌ها به صورت دوره‌ای
    }, 5000); // هر 5 ثانیه پیام‌ها را چک می‌کنیم

    return () => clearInterval(interval); // پاک کردن interval در هنگام unmount شدن کامپوننت
  }, [fetchMessages]);

  return { messages, loading, error, markMessagesAsRead, fetchMessages };
};

export default usePollingMessages;
