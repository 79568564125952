import React, { useState, useEffect } from 'react';
import MyDocument from './MyDocument'; // مسیر فایل PDF
import { PDFDownloadLink } from '@react-pdf/renderer';
import '../style/auctions.css';
import '../style/datesAuctions.css';
import '../style/responsive/auctionsR.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faMap, faCalendarAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import moment from 'jalali-moment';

const Mozaiede = () => {
  const [filteredAuctions, setFilteredAuctions] = useState([]);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    fetch(`https://hi.irtwitch.ir/api/auctions?_t=${new Date().getTime()}`)
      .then(response => response.json())
      .then(data => {
        const activeAuctions = data.filter(auction => auction.is_active);

        // مرتب‌سازی بر اساس تاریخ
        activeAuctions.sort((a, b) => new Date(a.date) - new Date(b.date));

        setFilteredAuctions(activeAuctions);
        localStorage.setItem('auctions', JSON.stringify(activeAuctions));
      })
      .catch(error => console.error('Error fetching auctions:', error));
  }, []);

  const todayJalali = moment().locale('fa').format('YYYY/M/D');

  const toggleModal = (auction = null) => {
    setShowModal(prevShowModal => !prevShowModal);
    setSelectedAuction(auction);
  };

  const handleFilter = (type) => {
    if (filter === type) {
      setFilter(null); // اگر دوباره کلیک شد، فیلتر برداشته شود و به حالت اولیه برگردد
    } else {
      setFilter(type);
    }
  };

  const filteredData = filteredAuctions.filter(auction => {
    const auctionDate = moment(auction.date, 'YYYY/M/D').locale('fa');
    const today = moment(todayJalali, 'YYYY/M/D').locale('fa');
    const daysRemaining = auctionDate.diff(today, 'days');

    if (filter === 'online' && auction.is_online_offline) return true;
    if (filter === 'offline' && !auction.is_online_offline) return true;
    if (filter === 'ended' && daysRemaining <= 0) return true;
    if (filter === null) return true;

    return false;
  });

  return (
    <>
      <div className="color-guide">
        <div
          style={{
            border: `2px solid ${filter === 'online' ? 'green' : 'green'}`,
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            display: "inline-block",
            boxShadow: `0 0 15px ${filter === 'online' ? 'green' : 'green'}`,
            backgroundColor: filter === 'online' ? 'green' : 'transparent', // پر شدن مربع با رنگ کادر
            cursor: "pointer"
          }}
          onClick={() => handleFilter('online')}
        ></div>
        <p style={{ cursor: "pointer" }} onClick={() => handleFilter('online')}>: مزایده آنلاین</p>

        <div
          style={{
            border: `2px solid ${filter === 'offline' ? 'bule' : 'blue'}`,
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            display: "inline-block",
            boxShadow: `0 0 15px ${filter === 'offline' ? 'bule' : 'blue'}`,
            backgroundColor: filter === 'offline' ? 'blue' : 'transparent', // پر شدن مربع با رنگ کادر
            cursor: "pointer"
          }}
          onClick={() => handleFilter('offline')}
        ></div>
        <p style={{ cursor: "pointer" }} onClick={() => handleFilter('offline')}>: مزایده آفلاین</p>

        <div
          style={{
            border: `2px solid ${filter === 'ended' ? 'silver' : 'gray'}`,
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            display: "inline-block",
            boxShadow: `0 0 15px ${filter === 'ended' ? 'silver' : 'gray'}`,
            backgroundColor: filter === 'ended' ? 'silver' : 'transparent', // پر شدن مربع با رنگ کادر
            cursor: "pointer"
          }}
          onClick={() => handleFilter('ended')}
        ></div>
        <p style={{ cursor: "pointer" }} onClick={() => handleFilter('ended')}>: مزایده به پایان رسیده</p>

        <PDFDownloadLink
          document={<MyDocument auctions={filteredAuctions} />}
          fileName="auction_dates.pdf"
        >
          {({ loading }) => (
            <span>{loading ? 'در حال آماده‌سازی...' : <FontAwesomeIcon style={{ color: "white", margin: "20px" }} icon={faDownload} />}</span>
          )}
        </PDFDownloadLink>

      </div>
      <div className="auctions-container">
        <div className="auctions-container-date">
          {filteredData.length > 0 ? (
            filteredData.map(auction => {
              const auctionDate = moment(auction.date, 'YYYY/M/D').locale('fa');
              const today = moment(todayJalali, 'YYYY/M/D').locale('fa');
              const daysRemaining = auctionDate.diff(today, 'days');

              let borderColor = auction.is_online_offline ? "green" : "blue";
              if (daysRemaining <= 0) {
                borderColor = "gray";
              }

              return (
                <div className="auctiondate" key={auction.id} style={{ borderRadius: "5px", height: "110px" }}>
                  <div className="auctiondatecart" style={{
                    border: `2px solid ${borderColor}`,
                    color: "white",
                    textAlign: "center",
                    padding: "10px",
                    boxShadow: `0 0 15px ${borderColor}`,  // اضافه کردن کادر نئونی
                    background: '#333' // اضافه کردن گرادیانت به پس‌زمینه
                  }}>
                    <p>تاریخ مزایده: <span>{auction.date}</span></p>
                    <p>
                      {daysRemaining > 0 ? (
                        `روزهای باقی‌مانده: ${daysRemaining} روز`
                      ) : (
                        `به پایان رسیده`
                      )}
                    </p>
                    <button className="btn btn-dark" onClick={() => toggleModal(auction)}>مشاهده</button>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center", fontFamily: "VAZIRT" }}>
              <p>هیچ مزایده‌ای با این نام پیدا نشد.</p>
            </div>
          )}

          {showModal && selectedAuction && (
            <div className={`modal ${showModal ? 'modal-active' : ''}`}>
              <div className={`modal-content ${showModal ? 'modal-active-content' : ''}`}>
                <div className="close-button" onClick={() => toggleModal()}>
                  <span>&times;</span>
                </div>
                <div className="modal-images">
                  {Array.isArray(selectedAuction.images) ? (
                    selectedAuction.images.map((image, index) => (
                      <img
                        key={index}
                        src={`https://hi.irtwitch.ir${image}`}
                        alt={`تصویر ${index + 1} از ${selectedAuction.title}`}
                        className="img-thumbnail"
                        style={{ margin: "5px", width: "300px" }}
                      />
                    ))
                  ) : (
                    <img
                      src={`https://hi.irtwitch.ir${selectedAuction.images}`}
                      alt={selectedAuction.title}
                      className="img-thumbnail"
                      style={{ margin: "5px", width: "300px" }}
                    />
                  )}
                </div>
                <div className="data">
                  <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>{selectedAuction.title}</p>
                  <p><FontAwesomeIcon icon={faCalendarAlt} /> تاریخ مزایده: <span>{selectedAuction.date}</span></p>
                  <p><FontAwesomeIcon icon={faLocationDot} /> <span>{selectedAuction.location}</span></p>
                </div>
                <div className="details">
                  <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>جزئیات کامل مزایده:</p>
                  <p>{selectedAuction.details}</p>
                  <br />
                  <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>اطلاعات:</p>
                  <p><FontAwesomeIcon icon={faPhone} /> شماره تماس: {selectedAuction.phonenumber}</p>
                  <p><FontAwesomeIcon icon={faMap} /> آدرس محل مزایده: {selectedAuction.address}</p>
                  <p>این مزایده به صورت <span style={{ fontFamily: "VAZIR", color: selectedAuction.is_online_offline === true ? "green" : "red" }}>{selectedAuction.is_online_offline === true ? "آنلاین" : "آفلاین"}</span> است .</p>
                  {selectedAuction.is_online_offline === true ? <button className="btn btn-success w-100">شرکت در مزایده</button> : ""}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Mozaiede;
