import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import vazirFont from "../style/font/vazir-font-v29.1.0/Vazir-Medium.ttf";

// ثبت فونت سفارشی
Font.register({
  family: "Vazir",
  src: vazirFont,
});

// تعریف استایل
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    fontFamily: "Vazir",
    direction: "rtl", // تنظیم راست به چپ برای کل صفحه
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "right", // راست‌چین کردن متن
  },
});

const MyDocument = ({ auctions }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>تاریخ‌های مزایده‌ها</Text>
        {auctions.length > 0 ? (
          auctions.map((auction, index) => (
            <View key={index}>
              <Text style={styles.text}>
                تاریخ مزایده: {auction.date} {"    "} عنوان مزایده:{" "}
                {auction.title}
              </Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>هیچ مزایده‌ای برای نمایش وجود ندارد.</Text>
        )}
      </View>
    </Page>
  </Document>
);

export default MyDocument;
