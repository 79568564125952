import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-jalaali';

const MessageBox = ({ storeData, messages, onSendMessage, onClose }) => {
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);

  const handleSendMessage = () => {
    if (message.trim()) { // Check if message is not just whitespace
      onSendMessage(message);
      setMessage(''); // Clear the message input after sending
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
      <div className="blur-background" onClick={onClose}></div>
      <div className="massegwithstor">
        <div className="masseges">
          {messages.map((msg, index) => {
            const isOwnMessage = msg.username === JSON.parse(localStorage.getItem('authData')).idcode;
            return (
              <div
                key={index}
                className={`message-item ${isOwnMessage ? 'message-item-right' : 'message-item-left'}`}
              >
                <div>{msg.comment}</div>
                <div className="message-timestamp">
                  {moment(msg.timestamp).locale('fa').format('jM/jD HH:mm')} {/* Month and Day only */}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
        <div className='sender'>
          <input
            type="text"
            name='comment'
            value={message}
            onChange={handleChange}
            placeholder='پیام خود را وارد کنید'
          />
          <button className="btn btn-success" onClick={handleSendMessage}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
        <button className="btn btn-danger btn-close-chat" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </>
  );
};

export default MessageBox;
