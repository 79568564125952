import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./Components/home";
import Login from "./Components/login";
import BackgroundSwitcher from "./Components/BackgroundSwitcher";
import Profile from "./Components/profile";
import Store from "./Components/stors";
import Management from "./Components/management";
import Auctionstartpage from "./Components/auctionStartPage";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    if (authData && authData.idcode) {
      setIsAuthenticated(true);
      setIsAdmin(authData.isAdmin);
    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
    }
  }, []);

  return (
    <div className="background">
      <div className="BackgroundSwitcher">
        <BackgroundSwitcher />
      </div>
      <div className="extra1"></div>
      <div className="extra2"></div>
      <div className="extra3"></div>
      <div className="extra4"></div>
      <div className="extra5"></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/profile/:idcode"
          element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
        />
        <Route
          path="/management/:idcode"
          element={
            isAuthenticated && isAdmin ? (
              <Management />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/login"
          element={
            <Login
              onLogin={() => {
                const authData = JSON.parse(localStorage.getItem("authData"));
                setIsAuthenticated(true);
                setIsAdmin(authData.isAdmin);
              }}
            />
          }
        />
        <Route path="/stores/:storeName" element={<Store />} />
        <Route path="/stores/auctionstart/:id" element={<Auctionstartpage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default App;
