import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Spinner, Alert } from "react-bootstrap";

const AuctionsList = ({ idCodeUser }) => {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeAuctionId, setActiveAuctionId] = useState(null);
  const [storeBlocked, setStoreBlocked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStoreAndAuctions = async () => {
      try {
        // Fetch store information to check if it is blocked
        const storeResponse = await axios.get(`https://hi.irtwitch.ir/api/stores/${idCodeUser}`);
        const store = storeResponse.data.store;

        if (store.blocked) {
          setStoreBlocked(true);
          setLoading(false);
          return;
        }

        // Fetch auctions if the store is not blocked
        const auctionsResponse = await axios.get(`https://hi.irtwitch.ir/api/auctions/user/${idCodeUser}`);

        // Filter auctions based on is_active and is_online_offline
        const filteredAuctions = auctionsResponse.data.filter(
          (auction) => auction.is_active && auction.is_online_offline
        );

        // Set filtered auctions
        setAuctions(filteredAuctions);

        // Find if there's an active auction
        const activeAuction = filteredAuctions.find(
          (auction) => auction.is_start === true
        );
        if (activeAuction) {
          setActiveAuctionId(activeAuction.id);
        }
      } catch (err) {
        setError(err.response?.data?.error || "خطا در بارگذاری داده‌ها");
      } finally {
        setLoading(false);
      }
    };

    fetchStoreAndAuctions();
  }, [idCodeUser]);

  const startAuction = async (id) => {
    if (activeAuctionId && activeAuctionId !== id) {
      alert("برای استارت مزایده جدید، ابتدا مزایده فعال فعلی را خاموش کنید.");
      return;
    }

    try {
      await axios.patch(`https://hi.irtwitch.ir/api/auctions/${id}/is_start`, {
        is_start: true,
      });
      setAuctions((prevAuctions) =>
        prevAuctions.map((auction) =>
          auction.id === id ? { ...auction, is_start: true } : auction
        )
      );
      setActiveAuctionId(id);
      alert("مزایده با موفقیت استارت شد!");

      // Redirect to the auction page after starting
      navigate(`/stores/auctionstart/${id}`);
    } catch (err) {
      alert(err.response?.data?.error || "خطا در استارت مزایده");
    }
  };

  const stopAuction = async (id) => {
    try {
      await axios.patch(`https://hi.irtwitch.ir/api/auctions/${id}/is_start`, {
        is_start: false,
      });
      setAuctions((prevAuctions) =>
        prevAuctions.map((auction) =>
          auction.id === id ? { ...auction, is_start: false } : auction
        )
      );
      setActiveAuctionId(null);
      alert("مزایده با موفقیت خاموش شد!");
    } catch (err) {
      alert(err.response?.data?.error || "خطا در خاموش کردن مزایده");
    }
  };

  if (loading) return <Spinner animation="border" />;

  if (storeBlocked) {
    return <Alert variant="warning">برای شروع مزایده‌ها باید فروشگاه از حالت مسدود بودن خارج شود.</Alert>;
  }

  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container>
      <h2 className="my-4">لیست مزایده‌ها</h2>
      {auctions.length === 0 ? (
        <p>هیچ مزایده‌ای یافت نشد.</p>
      ) : (
        <Row>
          {auctions.map((auction) => (
            <Col md={4} key={auction.id} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>{auction.title}</Card.Title>
                  <Card.Text>{auction.description}</Card.Text>
                  <Card.Text><small className="text-muted">{auction.date}</small></Card.Text>
                  <Card.Text>
                    وضعیت استارت:{" "}
                    {auction.is_start ? "شروع شده" : "شروع نشده"}
                  </Card.Text>
                  {auction.is_start ? (
                    <>
                      <Button variant="danger" onClick={() => stopAuction(auction.id)}>
                        خاموش کردن مزایده
                      </Button >
                      <p style={{ display: "inline-block", color: "black", textDecorationn: "none", textAlign: "center", margin: "20px" }} ><NavLink to={`/stores/auctionstart/${auction.id}`}>لینک مزایده</NavLink></p>
                    </>

                  ) : (

                    <Button
                      variant="primary w-100"
                      onClick={() => startAuction(auction.id)}
                      disabled={!!activeAuctionId && activeAuctionId !== auction.id}
                    >
                      {auction.is_start ? "مزایده شروع شده" : "استارت مزایده"}
                    </Button>




                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )
      }
    </Container >
  );
};

export default AuctionsList;
