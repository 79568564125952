import React, { useEffect, useState } from "react";
import sun from "../style/image/sunny.png";
import moon from "../style/image/night-mode.png";

const BackgroundSwitcher = () => {
  const [isNightMode, setIsNightMode] = useState(() => {
    // بازیابی حالت از LocalStorage
    return localStorage.getItem("isNightMode") === "true";
  });

  useEffect(() => {
    // اعمال کلاس‌های مناسب به body
    document.body.classList.toggle("night-mode", isNightMode);
    document.body.classList.toggle("day-mode", !isNightMode);
  }, [isNightMode]);

  const toggleMode = () => {
    setIsNightMode((prevMode) => {
      const newMode = !prevMode;
      // ذخیره حالت در LocalStorage
      localStorage.setItem("isNightMode", newMode);
      return newMode;
    });
  };

  return (
    <img
      src={isNightMode ? sun : moon}
      width={"25px"}
      alt="Toggle mode"
      onClick={toggleMode}
      style={{ cursor: "pointer", transition: "all 1s ease" }}
    />
  );
};

export default BackgroundSwitcher;
