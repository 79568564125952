import React, { useState } from "react";
import Calendar from 'react-calendar';
import moment from 'moment-jalaali'; // برای مدیریت تاریخ شمسی
import 'react-calendar/dist/Calendar.css'; // استایل‌های تقویم

const CreateAuction = ({ idCodeUser, onClose }) => {
  const [auctionData, setAuctionData] = useState({
    title: "",
    description: "",
    date: moment().format('jYYYY/jM/jD'), // تاریخ پیش‌فرض به روز جاری
    location: "",
    details: "",
    phonenumber: "",
    address: "",
  });
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAuctionData({ ...auctionData, [name]: value });
  };

  const handleFileChange = (e) => {
    setImages(Array.from(e.target.files) || []);
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY/M/D') : '';
  };

  const handleDateChange = (date) => {
    setAuctionData(prevState => ({
      ...prevState,
      date: moment(date).format('jYYYY/jM/jD')
    }));
    setShowCalendar(false);
  };

  const handleSubmit = async () => {
    const {
      title,
      description,
      date,
      location,
      details,
      phonenumber,
      address,
    } = auctionData;

    if (
      !title ||
      !description ||
      !date ||
      !location ||
      !details ||
      !phonenumber ||
      !address ||
      images.length === 0
    ) {
      setError("تمام فیلدها و حداقل یک تصویر الزامی است.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("date", date); // تاریخ به فرمت شمسی
    formData.append("location", location);
    formData.append("details", details);
    formData.append("phonenumber", phonenumber);
    formData.append("address", address);

    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/auctions/${idCodeUser}`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        alert("مزایده با موفقیت ایجاد شد!");
        onClose();
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError("خطا در برقراری ارتباط با سرور.");
    }
  };

  return (
    <div className="edit-modal">
      <div className="edit-modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <div className="inputsEditModal">
          <h2>ایجاد مزایده جدید</h2>

          <div className="form-group">
            <input
              type="text"
              name="title"
              placeholder="عنوان"
              value={auctionData.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="description"
              placeholder="توضیحات"
              value={auctionData.description}
              onChange={handleInputChange}
            />
          </div>
          <input
            type="text"
            onClick={() => setShowCalendar(!showCalendar)}
            name="date"
            value={formatDate(auctionData.date)}
            placeholder="تاریخ"
            readOnly
          />
          {showCalendar && (
            <Calendar
              onChange={handleDateChange}
              value={moment(auctionData.date, 'jYYYY/jM/jD').toDate()}
              locale="fa"
            />
          )}
          <div className="form-group">
            <input
              type="text"
              name="location"
              placeholder="شهر مزایده"
              value={auctionData.location}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="details"
              placeholder="جزیات مزایده"
              value={auctionData.details}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="phonenumber"
              placeholder="شماره تماس"
              value={auctionData.phonenumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="address"
              placeholder="آدرس"
              value={auctionData.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="file"
              name="images"
              multiple
              onChange={handleFileChange}
            />
          </div>
          <div className="buttons">
            <button className="save-button" onClick={handleSubmit}>
              ثبت مزایده
            </button>
            <button className="cancel-button" onClick={onClose}>
              بستن
            </button>
          </div>
          {error && <p className="text-danger mt-2">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreateAuction;
