import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserDetails = ({ userData, isEditingProfile, editedUserData, handleProfileChange, handleProfileEditToggle, handleSaveProfile, creationError }) => {
  return (
    <div className="container mt-4">
      {userData ? (
        <div className="card shadow-sm p-4">
          {!isEditingProfile ? (
            <>
              <h3 className="mb-4 text-center">اطلاعات کاربری</h3>
              <p><strong>نام:</strong> {userData.name}</p>
              <p><strong>نام خانوادگی:</strong> {userData.family}</p>
              <p><strong>ایمیل:</strong> {userData.email}</p>
              <p><strong>کد ملی:</strong> {userData.idcode}</p>
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary mt-3" onClick={handleProfileEditToggle}>ویرایش اطلاعات</button>
              </div>
            </>
          ) : (
            <div className="edit-profile-form">
              <h3 className="mb-4 text-center">ویرایش اطلاعات</h3>
              <div className="mb-3">
                <label className="form-label">نام</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="نام"
                  value={editedUserData.name}
                  onChange={handleProfileChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">نام خانوادگی</label>
                <input
                  type="text"
                  className="form-control"
                  name="family"
                  placeholder="نام خانوادگی"
                  value={editedUserData.family}
                  onChange={handleProfileChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">ایمیل</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="ایمیل"
                  value={editedUserData.email}
                  onChange={handleProfileChange}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button className="btn btn-success" onClick={handleSaveProfile}>ذخیره تغییرات</button>
                <button className="btn btn-secondary" onClick={handleProfileEditToggle}>انصراف</button>
              </div>
              {creationError && <p className="text-danger mt-3">{creationError}</p>}
            </div>
          )}
        </div>
      ) : (
        <div className="alert alert-warning text-center">
          اطلاعاتی یافت نشد.
        </div>
      )}
    </div>
  );
};

export default UserDetails;
