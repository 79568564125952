// src/components/ToggleCommentsButton.js
import React from "react";
import axios from "axios";

const ToggleCommentsButton = ({
  auction,
  user,
  idCodeUser,
  showComments,
  setShowComments,
}) => {
  const handleToggleComments = async () => {
    if (user && user.idcode === idCodeUser) {
      try {
        const newCommentsClosedStatus = !showComments;
        setShowComments(newCommentsClosedStatus);

        await axios.put(
          `https://hi.irtwitch.ir/api/auctions/comments_status/${auction.id}`,
          { commentsClosed: !newCommentsClosedStatus }
        );
      } catch (error) {
        console.error("خطا در تغییر وضعیت کامنت‌ها:", error);
        alert("خطا در تغییر وضعیت کامنت‌ها");
      }
    } else {
      alert("شما مجوز انجام این عمل را ندارید.");
    }
  };

  return (
    <button className="btn" onClick={handleToggleComments}>
      {showComments ? "بستن کامنت‌ها" : "باز کردن کامنت‌ها"}
    </button>
  );
};

export default ToggleCommentsButton;
