import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faMap, faCalendarAlt, faTrash, faToggleOn, faToggleOff, faEdit } from '@fortawesome/free-solid-svg-icons';
import "../../style/auctionList.css";
import Calendar from 'react-calendar';
import moment from 'moment-jalaali'; // برای مدیریت تاریخ شمسی
import 'react-calendar/dist/Calendar.css'; // استایل‌های تقویم

const AuctionList = ({ idCodeUser, showDeleteButton = true, showStatusButton = true, showEditBoxButton = true, showInactiveAuctions = true }) => {
  const [auctions, setAuctions] = useState([]);
  const [error, setError] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [editedAuction, setEditedAuction] = useState({});
  const [removedImages, setRemovedImages] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const formatDate = (date) => {
    return date ? moment(date).format('jYYYY/jM/jD') : '';
  };

  const formatDate1 = (date) => {
    return date ? moment(date).format('YYYY/M/D') : '';
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setEditedAuction(prevState => ({
      ...prevState,
      date: moment(date).format('jYYYY/jM/jD')
    }));
    setShowCalendar(false);
  };

  const handleCalendarClick = () => {
    setShowCalendar(prev => !prev);
  };

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        const response = await fetch(`https://hi.irtwitch.ir/api/auctions/user/${idCodeUser}`);
        if (!response.ok) {
          throw new Error('مزایده ای وجود ندارد.');
        }
        const data = await response.json();
        setAuctions(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchAuctions();
  }, [idCodeUser]);

  const toggleModal = (auction = null) => {
    setShowModal(prevShowModal => !prevShowModal);
    setSelectedAuction(auction);
    setEditMode(null);
    setEditedAuction({});
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedAuction(prevState => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (e) => {
    setEditedAuction(prevState => ({
      ...prevState,
      images: [...(prevState.images || []), ...Array.from(e.target.files)]
    }));
  };

  const handleRemoveImage = (image) => {
    setRemovedImages(prev => [...prev, image]);
    setEditedAuction(prevState => ({
      ...prevState,
      images: prevState.images.filter(img => img !== image)
    }));
  };

  const startEdit = (auction) => {
    if (editMode === auction.id) {
      setEditMode(null);
      setEditedAuction({});
    } else {
      setEditedAuction({ ...auction });
      setSelectedDate(moment(auction.date, 'jYYYY/jM/jD').toDate());
      setShowCalendar(false);
      setEditMode(auction.id);
    }
  };

  const saveEdit = async () => {
    const formData = new FormData();

    formData.append('title', editedAuction.title);
    formData.append('description', editedAuction.description);
    formData.append('date', editedAuction.date);
    formData.append('location', editedAuction.location);
    formData.append('details', editedAuction.details);
    formData.append('phonenumber', editedAuction.phonenumber);
    formData.append('address', editedAuction.address);

    editedAuction.images.forEach((file) => {
      if (file instanceof File) {
        formData.append('images', file);
      }
    });

    if (removedImages.length > 0) {
      formData.append('removeImages', removedImages.join(','));
    }

    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/auctions/${editedAuction.id}`, {
        method: 'PATCH',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`خطا در ویرایش مزایده: ${errorData}`);
      }

      const updatedAuction = await response.json();
      setAuctions(prevAuctions =>
        prevAuctions.map(auction =>
          auction.id === updatedAuction.id ? updatedAuction : auction
        )
      );
      // بستن باکس ویرایش پس از ذخیره موفق
      setEditMode(null);
      setEditedAuction({});
      setRemovedImages([]);
    } catch (err) {
      setError(err.message);
    }
  };

  const cancelEdit = () => {
    setEditMode(null);
    setEditedAuction({});
  };

  const deleteAuction = async (auctionId) => {
    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/auctions/${auctionId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('خطا در حذف مزایده');
      }
      // حذف مزایده از لیست پس از موفقیت‌آمیز بودن حذف
      setAuctions(prevAuctions => prevAuctions.filter(auction => auction.id !== auctionId));
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleAuctionStatus = async (auctionId, currentStatus) => {
    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/auctions/${auctionId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_active: !currentStatus }),
      });
      if (!response.ok) {
        throw new Error('خطا در به‌روزرسانی وضعیت مزایده');
      }
      setAuctions(prevAuctions => prevAuctions.map(auction =>
        auction.id === auctionId ? { ...auction, is_active: !currentStatus } : auction
      ));
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleAuctionis_online_offline = async (auctionId, currentStatus) => {
    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/auctions/${auctionId}/is_online_offline`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_online_offline: !currentStatus }),
      });
      if (!response.ok) {
        throw new Error('خطا در به‌روزرسانی وضعیت مزایده');
      }
      setAuctions(prevAuctions => prevAuctions.map(auction =>
        auction.id === auctionId ? { ...auction, is_online_offline: !currentStatus } : auction
      ));
    } catch (err) {
      setError(err.message);
    }
  };


  // Filter auctions based on is_active status and showInactiveAuctions prop
  const filteredAuctions = showInactiveAuctions ? auctions : auctions.filter(auction => auction.is_active);

  return (
    <div className="auctions-container">
      {error && <p className="error-message">{error}</p>}

      {filteredAuctions.map(auction => (
        <div className="auction" key={auction.id}>
          <div
            className="img"
            style={{
              backgroundImage: `url(https://hi.irtwitch.ir/${auction.images[0]})`,
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          ></div>
          <div className="caption">
            <div className="titel">
              <p>{auction.title}</p>
            </div>
            <div className="datacaption">
              <p>{auction.description}</p>
              <p>تاریخ مزایده: <span>{formatDate1(auction.date)}</span></p>
              <p><FontAwesomeIcon icon={faLocationDot} /> <span>{auction.location}</span></p>
            </div>
            <button className="btn btn-dark" onClick={() => toggleModal(auction)}>مشاهده</button>
            {showEditBoxButton && (
              <button
                style={{ position: "relative", top: " -280px", right: "160px" }}
                className="btn btn-secondary"
                onClick={() => startEdit(auction)}
              >

                <FontAwesomeIcon icon={faEdit} />
              </button>)}
          </div>
        </div>
      ))}

      {showModal && selectedAuction && (
        <div className={`modal ${showModal ? 'modal-active' : ''}`}>
          <div className={`modal-content ${showModal ? 'modal-active-content' : ''}`}>
            <div className="close-button" onClick={() => toggleModal()}>
              <span>&times;</span>
            </div>
            {/* نمایش تمام تصاویر به صورت اسلاید یا در کنار هم */}
            <div className="modal-images">
              {Array.isArray(selectedAuction.images) ? (
                selectedAuction.images.map((image, index) => (
                  <img
                    key={index}
                    src={`https://hi.irtwitch.ir${image}`}
                    alt={`تصویر ${index + 1} از ${selectedAuction.title}`}
                    className="img-thumbnail"
                    style={{ margin: "5px", width: "300px" }}
                  />
                ))
              ) : (
                <img
                  src={`https://hi.irtwitch.ir${selectedAuction.images}`}
                  alt={selectedAuction.title}
                  className="img-thumbnail"
                  style={{ margin: "5px", width: "300px" }}
                />
              )}
            </div>
            <div className="data">
              <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>{selectedAuction.title}</p>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> تاریخ مزایده: <span>{selectedAuction.date}</span></p>
              <p><FontAwesomeIcon icon={faLocationDot} /> <span>{selectedAuction.location}</span></p>
            </div>
            <div className="details">
              <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>جزئیات کامل مزایده:</p>
              <p>{selectedAuction.details}</p>
              <br />
              <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>اطلاعات:</p>
              <p><FontAwesomeIcon icon={faPhone} /> شماره تماس: {selectedAuction.phonenumber}</p>
              <p><FontAwesomeIcon icon={faMap} /> آدرس محل مزایده: {selectedAuction.address}</p>
              <p>این مزایده به صورت <span style={{ fontFamily: "VAZIR", color: selectedAuction.is_online_offline === true ? "green" : "red" }}>{selectedAuction.is_online_offline === true ? "آنلاین" : "آفلاین"}</span> است .</p>
              {selectedAuction.is_online_offline === true ? <button className="btn btn-success w-100">شرکت در مزایده</button> : ""}
            </div>
          </div>
        </div>
      )}

      {editMode && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <div className="close-button" onClick={cancelEdit}>
              <span>&times;</span>
            </div>
            {auctions.map(auction => (
              <div className="auction" style={{ height: "auto" }} key={auction.id}>
                {editMode === auction.id && (
                  <div className="edit-buttons show" style={{ width: "300px", position: "relative", left: "-60px" }}>
                    {showStatusButton && (
                      <button
                        style={{ backgroundColor: auction.is_active ? 'green' : 'gray', minWidth: 92, borderRadius: "5px 0 0 15px" }}
                        className="btn btn-toggle"
                        onClick={() => toggleAuctionStatus(auction.id, auction.is_active)}
                      >
                        <FontAwesomeIcon icon={auction.is_active ? faToggleOn : faToggleOff} />
                        {auction.is_active ? 'فعال' : 'غیرفعال'}
                      </button>
                    )}
                    {showDeleteButton && (
                      <button
                        style={{ backgroundColor: 'red', color: 'white', position: 'relative', minWidth: 92, borderRadius: "0" }}
                        className="btn btn-danger"
                        onClick={() => {
                          deleteAuction(auction.id);
                          cancelEdit();
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} /> حذف
                      </button>
                    )}
                    {showStatusButton && (
                      <button
                        style={{ backgroundColor: auction.is_online_offline ? 'green' : 'gray', minWidth: 92, borderRadius: "0 15px 5px 0" }}
                        className="btn btn-toggle"
                        onClick={() => toggleAuctionis_online_offline(auction.id, auction.is_online_offline)}
                      >
                        <FontAwesomeIcon icon={auction.is_online_offline ? faToggleOn : faToggleOff} />
                        {auction.is_online_offline ? 'آنلاین' : 'آفلاین'}
                      </button>
                    )}

                  </div>
                )}
              </div>
            ))}
            <div className="inputsEditModal">
              <h2>ویرایش مزایده</h2>
              <input
                type="text"
                name="title"
                value={editedAuction.title || ''}
                onChange={handleEditChange}
                placeholder="عنوان"
              />
              <textarea
                name="description"
                value={editedAuction.description || ''}
                onChange={handleEditChange}
                placeholder="توضیحات"
              />
              <input
                type="text"
                name="date"
                value={formatDate(selectedDate) || editedAuction.date || ''}
                onClick={handleCalendarClick} // نمایش تقویم با کلیک
                placeholder="تاریخ"
                readOnly
              />
              {showCalendar && (
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate || moment(editedAuction.date, 'jYYYY/jM/jD').toDate()}
                  locale="fa"
                />
              )}
              <input
                type="text"
                name="location"
                value={editedAuction.location || ''}
                onChange={handleEditChange}
                placeholder="مکان"
              />
              <textarea
                name="details"
                value={editedAuction.details || ''}
                onChange={handleEditChange}
                placeholder="جزئیات"
              />
              <input
                type="text"
                name="phonenumber"
                value={editedAuction.phonenumber || ''}
                onChange={handleEditChange}
                placeholder="شماره تماس"
              />
              <input
                type="text"
                name="address"
                value={editedAuction.address || ''}
                onChange={handleEditChange}
                placeholder="آدرس"
              />
              <input
                type="file"
                multiple
                onChange={handleImageChange}
              />
              <div className="images-preview">
                {editedAuction.images && editedAuction.images.map((image, index) => (
                  <div key={index} className="image-preview">
                    <p>{index + 1 === 1 ? "تصویر اصلی :" : `تصویر ${index + 1}`}</p>
                    {image instanceof File ? (
                      <img src={URL.createObjectURL(image)} alt={`تصویر ${index + 1}`} />
                    ) : (
                      <img src={`https://hi.irtwitch.ir${image}`} alt={`تصویر ${index + 1}`} />
                    )}
                    <button onClick={() => handleRemoveImage(image)}>حذف تصویر {index + 1}</button>
                  </div>
                ))}
              </div>
              <button className="save-button" onClick={saveEdit}>ذخیره</button>
              <button className="cancel-button" onClick={cancelEdit}>انصراف</button>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuctionList;
