// src/components/ToggleBidsButton.js
import React from "react";
import axios from "axios";

const ToggleBidsButton = ({
  auction,
  user,
  idCodeUser,
  showBids,
  setShowBids,
}) => {
  const handleToggleBids = async () => {
    if (user && auction.idCodeUser === idCodeUser) {
      try {
        const newShowBidsStatus = !showBids;
        setShowBids(newShowBidsStatus);

        await axios.put(
          `https://hi.irtwitch.ir/api/auctions/${auction.id}/show_bids`,
          { showBids: newShowBidsStatus }
        );
      } catch (error) {
        console.error("خطا در تغییر وضعیت نمایش پیشنهادات:", error);
        alert("خطا در تغییر وضعیت نمایش پیشنهادات");
      }
    } else {
      alert("شما مجوز انجام این عمل را ندارید.");
    }
  };

  return (
    <button className="btn" onClick={handleToggleBids}>
      {showBids ? "پنهان کردن پیشنهادات قیمت" : "نمایش پیشنهادات قیمت"}
    </button>
  );
};

export default ToggleBidsButton;
