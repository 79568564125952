// src/components/ToggleBidsStatusButton.js
import React from "react";
import axios from "axios";

const ToggleBidsStatusButton = ({
  auction,
  user,
  idCodeUser,
  bidsClosed,
  setBidsClosed,
}) => {
  const handleToggleBidsStatus = async () => {
    if (user && auction.idCodeUser === idCodeUser) {
      try {
        const newBidsClosedStatus = !bidsClosed;
        setBidsClosed(newBidsClosedStatus);

        await axios.put(
          `https://hi.irtwitch.ir/api/auctions/${auction.id}/close_bids`,
          { closeBids: newBidsClosedStatus }
        );
      } catch (error) {
        console.error("خطا در تغییر وضعیت بستن پیشنهادات:", error);
        alert("خطا در تغییر وضعیت بستن پیشنهادات");
      }
    } else {
      alert("شما مجوز انجام این عمل را ندارید.");
    }
  };

  return (
    <button className="btn" onClick={handleToggleBidsStatus}>
      {bidsClosed
        ? "فعال‌سازی قیمت‌گذاری برای کاربران"
        : "غیرفعال‌سازی قیمت‌گذاری برای کاربران"}
    </button>
  );
};

export default ToggleBidsStatusButton;
