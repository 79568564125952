import React, { useState, useEffect } from "react";
import '../style/auctions.css';
import '../style/responsive/auctionsR.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faMap, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const Mozaiede = () => {
  const [auctions, setAuctions] = useState([]);
  const [filteredAuctions, setFilteredAuctions] = useState([]);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [blockedStores, setBlockedStores] = useState([]);

  // Fetch blocked stores
  useEffect(() => {
    fetch('https://hi.irtwitch.ir/api/stores')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch stores');
        }
        return response.json();
      })
      .then(data => {
        const blockedStores = data.stores.filter(store => store.blocked).map(store => store.idcode);
        setBlockedStores(blockedStores);
      })
      .catch(error => console.error('Error fetching blocked stores:', error));
  }, []);

  // Fetch auctions
  useEffect(() => {
    fetch(`https://hi.irtwitch.ir/api/auctions?_t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch auctions');
        }
        return response.json();
      })
      .then(data => {
        const activeAuctions = data.filter(auction => auction.is_active && !blockedStores.includes(auction.idCodeUser));
        setAuctions(activeAuctions);
        setFilteredAuctions(activeAuctions);
        localStorage.setItem('auctions', JSON.stringify(activeAuctions));
      })
      .catch(error => console.error('Error fetching auctions:', error));
  }, [blockedStores]);

  const toggleModal = (auction = null) => {
    setShowModal(prevShowModal => !prevShowModal);
    setSelectedAuction(auction);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = auctions.filter(auction =>
      auction.title.toLowerCase().includes(query) ||
      auction.description.toLowerCase().includes(query) ||
      auction.location.toLowerCase().includes(query)
    );
    setFilteredAuctions(filtered);
  };


  return (
    <>
      <Toaster />
      <div className="storslistSearchBox">
        <input
          type="text"
          placeholder="جستجوی مزایده‌ها..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="form-control mb-3"
        />
      </div>
      <div className="auctions-container">
        {filteredAuctions.length > 0 ? (
          filteredAuctions.map(auction => (
            <div className="auction" key={auction.id}>
              <div
                className="img"
                style={{
                  backgroundImage: `url(https://hi.irtwitch.ir${Array.isArray(auction.images) ? auction.images[0] : auction.images})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }}
              ></div>
              <div className="caption">
                <div className="titel">
                  <p>{auction.title}</p>
                </div>
                <div className="datacaption">
                  <p>{auction.description}</p>
                  <p>تاریخ مزایده: <span>{auction.date}</span></p>
                  <p><FontAwesomeIcon icon={faLocationDot} /> <span>{auction.location}</span></p>
                </div>
                <button className="btn btn-dark" onClick={() => toggleModal(auction)}>مشاهده</button>
              </div>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", fontFamily: "VAZIRT" }}>
            هیچ مزایده‌ای با این نام پیدا نشد.
          </p>
        )}

        {/* Modal */}
        {showModal && selectedAuction && (
          <div className={`modal ${showModal ? 'modal-active' : ''}`}>
            <div className={`modal-content ${showModal ? 'modal-active-content' : ''}`}>
              <div className="close-button" onClick={() => toggleModal()}>
                <span>&times;</span>
              </div>
              <div className="modal-images">
                {Array.isArray(selectedAuction.images) ? (
                  selectedAuction.images.map((image, index) => (
                    <img
                      key={index}
                      src={`https://hi.irtwitch.ir${image}`}
                      alt={`تصویر ${index + 1} از ${selectedAuction.title}`}
                      className="img-thumbnail"
                      style={{ margin: "5px", width: "300px" }}
                    />
                  ))
                ) : (
                  <img
                    src={`https://hi.irtwitch.ir${selectedAuction.images}`}
                    alt={selectedAuction.title}
                    className="img-thumbnail"
                    style={{ margin: "5px", width: "300px" }}
                  />
                )}
              </div>
              <div className="data">
                <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>{selectedAuction.title}</p>
                <p><FontAwesomeIcon icon={faCalendarAlt} /> تاریخ مزایده: <span>{selectedAuction.date}</span></p>
                <p><FontAwesomeIcon icon={faLocationDot} /> <span>{selectedAuction.location}</span></p>
              </div>
              <div className="details">
                <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>جزئیات کامل مزایده:</p>
                <p>{selectedAuction.details}</p>
                <br />
                <p style={{ textAlign: "center", fontFamily: "VAZIRB", fontSize: "20px" }}>اطلاعات:</p>
                <p><FontAwesomeIcon icon={faPhone} /> شماره تماس: {selectedAuction.phonenumber}</p>
                <p><FontAwesomeIcon icon={faMap} /> آدرس محل مزایده: {selectedAuction.address}</p>
                <p>این مزایده به صورت <span style={{ fontFamily: "VAZIR", color: selectedAuction.is_online_offline ? "green" : "red" }}>{selectedAuction.is_online_offline ? "آنلاین" : "آفلاین"}</span> است .</p>
                <NavLink
                  to={selectedAuction.is_start ? `/stores/auctionstart/${selectedAuction.id}` : "#"}
                  onClick={(e) => {
                    if (!selectedAuction.is_start) {
                      e.preventDefault(); // جلوگیری از هدایت به لینک
                      toast.error('هنوز مزایده شروع نشده!');
                    }
                  }}
                >
                  <button className="btn btn-success w-100">شرکت در مزایده</button>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Mozaiede;
