import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/stors.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Menu from '../Components/menu';
import StoreList from '../Components/storeList';
import AuctionList from './profileComponents/AuctionList';
import MessageBox from './profileComponents/MessageBox';

const Store = () => {
  const { storeName } = useParams();
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await fetch(`https://hi.irtwitch.ir/api/stores/by-name/${storeName}`);
        const data = await response.json();

        if (response.ok) {
          setStoreData(data.store);
          setError(null);
        } else {
          setError('فروشگاه مورد نظر یافت نشد.');
        }
      } catch (error) {
        setError('خطا در برقراری ارتباط با سرور.');
      } finally {
        setLoading(false);
      }
    };

    fetchStoreData();
  }, [storeName]);

  useEffect(() => {
    const fetchMessages = async () => {
      const authData = JSON.parse(localStorage.getItem('authData'));

      if (authData && authData.idcode && storeData?.idcode) {
        try {
          const response = await fetch(`https://hi.irtwitch.ir/api/users/comments/${authData.idcode}`);
          const data = await response.json();

          if (response.ok) {
            const filteredMessages = data.filter(
              msg =>
                (msg.senderId === authData.idcode && msg.receiverId === storeData.idcode) ||
                (msg.senderId === storeData.idcode && msg.receiverId === authData.idcode)
            );
            setMessages(filteredMessages);
          } else {
            console.error('Response error:', data);
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      }
    };

    fetchMessages();
  }, [storeData]);

  const toggleMessageBox = () => {
    const authData = JSON.parse(localStorage.getItem('authData'));

    if (!authData || !authData.idcode) {
      alert('برای ارسال پیام باید ثبت نام کنید.');
      return;
    }

    setShowMessageBox(prevState => !prevState);
  };

  const handleSendMessage = async (message) => {
    const authData = JSON.parse(localStorage.getItem('authData'));

    if (!authData || !authData.idcode) {
      alert('برای ارسال پیام باید وارد شوید.');
      return;
    }

    try {
      const response = await fetch('https://hi.irtwitch.ir/api/users/comment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          idUserComment: storeData.idcode,
          comment: message,
          username: authData.idcode
        })
      });

      const result = await response.json();

      if (response.ok) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            username: authData.idcode,
            comment: message,
            timestamp: new Date().toISOString()
          }
        ]);
      } else {
        alert(result.error || 'خطا در ارسال پیام.');
      }
    } catch (error) {
      alert('خطا در ارسال پیام.');
    }
  };

  if (loading) {
    return <div>در حال بارگذاری...</div>;
  }

  const authData = JSON.parse(localStorage.getItem('authData'));
  const isOwner = authData && authData.idcode === storeData.idcode;

  return (
    <>
      <Menu />
      <div className="container mt-4">
        {showMessageBox && (
          <MessageBox
            storeData={storeData}
            messages={messages}
            onSendMessage={handleSendMessage}
            onClose={toggleMessageBox}
          />
        )}
        {error && <div className="alert alert-danger">{error}</div>}

        {storeData && !storeData.blocked ? (
          <div className="storPage ecard">
            <div className="headerStor card-body d-flex">
              <img
                src={`https://hi.irtwitch.ir${storeData.imageUrl}`}
                alt={storeData.storeName}
                className="img-fluid me-3"
                style={{ maxWidth: '150px', maxHeight: '150px' }}
              />
              <div className="storename flex-grow-1">
                <p>
                  فـــــروشگاه: <span>{storeData.storeName}&nbsp;</span>
                  {storeData.blueTick && (
                    <FontAwesomeIcon icon={faCheckCircle} color="#1da1f2" />
                  )}
                </p>
                <p>شماره تماس: <span>{storeData.phone}</span></p>
                <p>آدرس: <span>{storeData.address}</span></p>
                <p>امتیاز:</p>
                {isOwner ? (
                  <p>به فروشگاه خودتان خوش آمدید.</p>
                ) : (
                  <div>
                    <button className="btn btn-danger">گزارش فروشگاه</button>
                    <button className="btn btn-primary m-3" onClick={toggleMessageBox}>ارتباط با فروشگاه</button>
                  </div>
                )}
              </div>
            </div>
            <div className="auctinsStors card-footer">
              <h5>لیست مزایده‌های فروشگاه</h5>
              <AuctionList idCodeUser={storeData.idcode} showDeleteButton={false} showEditBoxButton={false} showInactiveAuctions={false} />
            </div>
          </div>
        ) : (
          <>
            <div className="blocked-store text-center my-5">
              <div className="alert alert-danger d-inline-block px-5 py-4">
                این فروشگاه در حال حاضر در دسترس نیست.
              </div>
            </div>
            <h3>لیست فروشگاه‌ها:</h3>
            <StoreList />
          </>
        )}
      </div>
    </>
  );
};

export default Store;
