import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageBox from '../profileComponents/MessageBox'; // فرض بر این است که MessageBox در همان مسیر قرار دارد

const UsersList = ({ idcodeadmin }) => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const [updatedUser, setUpdatedUser] = useState({});
  const [messages, setMessages] = useState([]);
  const [currentChatUser, setCurrentChatUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://hi.irtwitch.ir/api/users');
        const filteredUsers = response.data.filter(user => user.idcode !== idcodeadmin);
        setUsers(filteredUsers);
      } catch (err) {
        setError('خطا در بارگذاری کاربران.');
        console.error(err);
      }
    };

    fetchUsers();
  }, [idcodeadmin]);

  useEffect(() => {
    if (currentChatUser) {
      const fetchMessages = async () => {
        try {
          const response = await fetch(`https://hi.irtwitch.ir/api/users/comments/${currentChatUser.idcode}`);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const data = await response.json();

          // فیلتر کردن پیام‌ها برای نمایش فقط پیام‌هایی که بین ادمین و کاربر انتخاب شده رد و بدل شده‌اند
          const filteredMessages = data.filter(
            msg =>
              (msg.senderId === idcodeadmin && msg.receiverId === currentChatUser.idcode) ||
              (msg.senderId === currentChatUser.idcode && msg.receiverId === idcodeadmin)
          );

          setMessages(filteredMessages);
        } catch (error) {
          console.error('Error fetching messages from the server.', error);
        }
      };

      fetchMessages();
    }
  }, [currentChatUser, idcodeadmin]);

  const handleEditClick = (user) => {
    setEditingUserId(user.idcode);
    setUpdatedUser(user);
  };

  const handleCancel = () => {
    setEditingUserId(null);
    setUpdatedUser({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`https://hi.irtwitch.ir/api/users/${updatedUser.idcode}`, updatedUser);
      setUsers(users.map(user => (user.idcode === updatedUser.idcode ? updatedUser : user)));
      setEditingUserId(null);
      setUpdatedUser({});
    } catch (err) {
      setError('خطا در ویرایش کاربر.');
      console.error(err);
    }
  };

  const handleBlock = async (user) => {
    try {
      await axios.put(`https://hi.irtwitch.ir/api/users/${user.idcode}`, { is_blocked: !user.is_blocked });
      setUsers(users.map(u => (u.idcode === user.idcode ? { ...u, is_blocked: !u.is_blocked } : u)));
    } catch (err) {
      setError('خطا در مسدود کردن کاربر.');
      console.error(err);
    }
  };

  const handleDelete = async (idcode) => {
    if (window.confirm("آیا از حذف این کاربر مطمئن هستید؟")) {
      try {
        await axios.delete(`https://hi.irtwitch.ir/api/users/${idcode}`);
        setUsers(users.filter(user => user.idcode !== idcode));
      } catch (err) {
        setError('خطا در حذف کاربر.');
        console.error(err);
      }
    }
  };

  const openChat = (user) => {
    setMessages([]); // پاک کردن پیام‌های قبلی
    setCurrentChatUser(user);
  };

  return (
    <div className="container mt-5" style={{ overflow: "scroll" }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr align="center">
            <th>نام</th>
            <th>نام خانوادگی</th>
            <th>ایمیل</th>
            <th>مسدود شده</th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.idcode} align="center">
              <td>
                {editingUserId === user.idcode ? (
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={updatedUser.name}
                    onChange={handleInputChange}
                  />
                ) : (
                  user.name
                )}
              </td>
              <td>
                {editingUserId === user.idcode ? (
                  <input
                    type="text"
                    name="family"
                    className="form-control"
                    value={updatedUser.family}
                    onChange={handleInputChange}
                  />
                ) : (
                  user.family
                )}
              </td>
              <td>
                {editingUserId === user.idcode ? (
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={updatedUser.email}
                    onChange={handleInputChange}
                  />
                ) : (
                  user.email
                )}
              </td>
              <td>{user.is_blocked ? 'بله' : 'خیر'}</td>
              <td>
                {editingUserId === user.idcode ? (
                  <>
                    <button className="btn btn-success btn-sm m-2" onClick={handleSave}>ذخیره</button>
                    <button className="btn btn-secondary btn-sm" onClick={handleCancel}>لغو</button>
                  </>
                ) : (
                  <>
                    <button className="btn btn-primary btn-sm m-2" onClick={() => handleEditClick(user)}>ویرایش</button>
                    <button className="btn btn-warning btn-sm m-2" onClick={() => handleBlock(user)}>
                      {user.is_blocked ? 'فعال کردن' : 'مسدود کردن'}
                    </button>
                    <button className="btn btn-danger btn-sm m-2" onClick={() => handleDelete(user.idcode)}>حذف</button>
                    <button className='btn btn-primary btn-sm m-2' onClick={() => openChat(user)}>پیام</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {currentChatUser && (
        <MessageBox
          storeData={currentChatUser} // ارسال اطلاعات کاربر به MessageBox
          messages={messages}
          onSendMessage={async (message) => {
            try {
              const response = await fetch('https://hi.irtwitch.ir/api/users/comment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  idUserComment: currentChatUser.idcode,
                  comment: message,
                  username: idcodeadmin,
                }),
              });

              if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

              setMessages(prevMessages => [
                ...prevMessages,
                {
                  username: idcodeadmin,
                  comment: message,
                  timestamp: new Date().toISOString(),
                  senderId: idcodeadmin,
                  receiverId: currentChatUser.idcode,
                },
              ]);
            } catch (error) {
              alert('خطا در ارسال پیام.');
              console.error('Error sending message.', error);
            }
          }}
          onClose={() => setCurrentChatUser(null)}
        />
      )}
    </div>
  );
};

export default UsersList;
