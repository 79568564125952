import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../style/storList.css';

const StoreList = () => {
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await fetch('https://hi.irtwitch.ir/api/stores/');
        const data = await response.json();

        if (response.ok) {
          const activeStores = data.stores.filter(store => !store.blocked); // فقط فروشگاه‌های غیر مسدود شده
          setStores(activeStores);
          setFilteredStores(activeStores);
        }
      } catch (error) {
        console.error('خطا در دریافت لیست فروشگاه‌ها:', error);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    const filtered = stores.filter(store =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStores(filtered);
  }, [searchTerm, stores]);

  return (
    <div>
      <div className="storslistSearchBox">
        <input
          type="text"
          placeholder="جستجو بر اساس نام فروشگاه..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="form-control mb-3"
        />
      </div>

      <div className="storslist">
        <ul>
          {filteredStores.length > 0 ? (
            filteredStores.map((store, index) => (
              <li key={index}>
                <NavLink className={'NavLink'} to={`/stores/${store.storeName}`}>
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(https://hi.irtwitch.ir${store.imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "5px 15px 5px 15px"
                    }}
                  ></div>
                  {store.storeName}
                </NavLink>
              </li>
            ))
          ) : (
            <p>هیچ فروشگاهی با این نام پیدا نشد.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default StoreList;
