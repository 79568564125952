import React from 'react';
import '../../style/UnreadBadge.css'; // فایل CSS برای استایل

const UnreadBadge = ({ count }) => {
  if (count === 0) return null; // اگر تعداد پیام‌های خوانده‌نشده صفر است، چیزی نمایش داده نشود

  return (
    <span className="unread-badge">
      {count}
    </span>
  );
};

export default UnreadBadge;
