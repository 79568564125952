import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import '../../style/storeinfo.css';

const StoreInfo = ({
  idcode,
  userStore,
  setUserStore,
  handleDeleteStore,
  handleCreateStore,
  checkStoreNameAvailability
}) => {
  const [showCreateStore, setShowCreateStore] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [creationError, setCreationError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newStoreName, setNewStoreName] = useState(userStore?.storeName || '');
  const [newPhone, setNewPhone] = useState(userStore?.phone || '');
  const [newAddress, setNewAddress] = useState(userStore?.address || '');
  const [contactInfoError, setContactInfoError] = useState(null);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(userStore?.imageUrl || '');

  const handleStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleCreateStoreToggle = () => {
    setShowCreateStore(!showCreateStore);
  };

  const handleCreateStoreClick = async () => {
    if (!storeName) {
      setCreationError('لطفاً نام فروشگاه را وارد کنید.');
      return;
    }

    const isStoreNameTaken = await checkStoreNameAvailability(storeName.toLowerCase().replace(/\s+/g, '-'));

    if (isStoreNameTaken) {
      setCreationError('نام فروشگاه تکراری است. لطفاً نام دیگری انتخاب کنید.');
      return;
    }

    handleCreateStore(storeName);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setNewStoreName(userStore?.storeName || '');
    setNewPhone(userStore?.phone || '');
    setNewAddress(userStore?.address || '');
  };

  const handleUpdateStoreInfo = async () => {
    if (!newStoreName && !newPhone && !newAddress) {
      setContactInfoError('لطفاً نام فروشگاه، شماره تماس یا آدرس جدید را وارد کنید.');
      return;
    }

    try {
      const response = await fetch('https://hi.irtwitch.ir/api/stores/update-store-info', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          idcode,
          storeName: newStoreName || userStore.storeName,
          phone: newPhone || userStore.phone,
          address: newAddress || userStore.address
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert('اطلاعات فروشگاه با موفقیت به‌روزرسانی شد!');
        setUserStore({
          ...userStore,
          storeName: newStoreName || userStore.storeName,
          phone: newPhone || userStore.phone,
          address: newAddress || userStore.address
        });
        setIsEditing(false);
        setContactInfoError(null);
      } else {
        setContactInfoError(data.error);
      }
    } catch (error) {
      setContactInfoError('خطا در برقراری ارتباط با سرور.');
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      setImageUploadError('لطفاً یک فایل انتخاب کنید.');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('idcode', idcode);
    formData.append('storeName', userStore.storeName);

    try {
      const response = await fetch('https://hi.irtwitch.ir/api/stores/upload-image', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        alert('تصویر فروشگاه با موفقیت آپلود شد!');
        setUploadedImageUrl(data.imageUrl);
        setIsEditingImage(false);
        setImageUploadError(null);
      } else {
        setImageUploadError(data.error);
      }
    } catch (error) {
      setImageUploadError('خطا در آپلود تصویر.');
    }
  };

  const handleEditImage = () => {
    setIsEditingImage(true);
  };

  return (
    <div className='storemanegment'>
      {userStore?.blocked ? (
        <div className="blocked-message">
          <h2>فروشگاه شما مسدود شده است</h2>
          <p>برای اطلاعات بیشتر می‌توانید با پشتیبانی صحبت کنید.</p>
        </div>
      ) : (
        <>
          <button
            className={`btn ${userStore ? 'btn-danger' : 'btn-primary'}`}
            onClick={() => {
              if (userStore) {
                handleDeleteStore();
              } else {
                handleCreateStoreToggle();
              }
            }}
          >
            {userStore ? 'حذف فروشگاه' : (showCreateStore ? 'بستن فرم ایجاد فروشگاه' : 'ایجاد فروشگاه')}
          </button>

          {showCreateStore && !userStore && (
            <div className='create-store-form'>
              <h2>ایجاد فروشگاه جدید</h2>
              <input
                type="text"
                placeholder="نام فروشگاه"
                value={storeName}
                onChange={handleStoreNameChange}
              />
              <button className='btn btn-success' onClick={handleCreateStoreClick}>
                تایید
              </button>
              {creationError && <p style={{ color: 'red' }}>{creationError}</p>}
            </div>
          )}

          {userStore && (
            <div className='store-info'>
              <h2>فروشگاه شما</h2>
              {!isEditing ? (
                <div className='editingStoreName'>
                  <div style={{ display: "flex" }}>
                    <p>نام فروشگاه: <span>{userStore.storeName}</span> &nbsp;</p>
                    <p>شماره تماس: <span>{userStore.phone}</span> &nbsp;</p>
                  </div>
                  <p style={{ float: "right" }}>آدرس فروشگاه: <span>{userStore.address}</span> &nbsp;</p>
                  <button className='btn btn-warning' onClick={handleEditToggle}>ویرایش اطلاعات</button>
                </div>
              ) : (
                <div className='edit-form'>
                  <input
                    type="text"
                    placeholder="نام جدید فروشگاه"
                    value={newStoreName}
                    onChange={(e) => setNewStoreName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="شماره تماس جدید"
                    value={newPhone}
                    onChange={(e) => setNewPhone(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="آدرس جدید"
                    value={newAddress}
                    onChange={(e) => setNewAddress(e.target.value)}
                  />
                  {contactInfoError && <p style={{ color: 'red' }}>{contactInfoError}</p>}
                  <button className='btn btn-success' onClick={handleUpdateStoreInfo}>ذخیره</button>
                  <button className='btn btn-secondary' onClick={handleEditToggle}>انصراف</button>
                </div>
              )}

              <p style={{ fontFamily: "VAZIRB" }}>
                <NavLink to={`/stores/${userStore.storeName.toLowerCase().replace(/\s+/g, '-')}`}>
                  <FontAwesomeIcon icon={faEye} />مشاهده فروشگاه
                </NavLink>
              </p>

              {uploadedImageUrl ? (
                <div className="editingImage">
                  <h3>تصویر فروشگاه</h3>
                  <img src={`https://hi.irtwitch.ir/${uploadedImageUrl}`} alt="Store" style={{ width: '200px', height: 'auto', borderRadius: " 5px 25px 0px 0px ", }} />
                  {!isEditingImage ? (
                    <button className='btn btn-warning' style={{ width: "200px", borderRadius: " 0px 0px 5px 25px", margin: "0", background: "white", color: "black", borderTop: "1px solid black", paddingTop: "4px" }} onClick={handleEditImage}>ویرایش تصویر</button>
                  ) : (
                    <div className="image-edit-container">
                      <input type="file" accept="image/*" onChange={handleImageUpload} />
                      <div className="image-edit-actions">
                        <button className='btn btn-secondary ' onClick={() => setIsEditingImage(false)}>انصراف</button>
                      </div>
                      {imageUploadError && <p style={{ color: 'red' }}>{imageUploadError}</p>}
                    </div>
                  )}
                </div>
              ) : (
                <div className="editingImage">
                  <p>تصویری برای فروشگاه آپلود نشده است.</p>
                  <button className='btn btn-primary' onClick={handleEditImage}>آپلود تصویر فروشگاه</button>
                  {isEditingImage && (
                    <div className="image-edit-container">
                      <input type="file" accept="image/*" onChange={handleImageUpload} />
                      <div className="image-edit-actions">
                        <button className='btn btn-secondary' onClick={() => setIsEditingImage(false)}>انصراف</button>
                      </div>
                      {imageUploadError && <p style={{ color: 'red' }}>{imageUploadError}</p>}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StoreInfo;
