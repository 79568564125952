import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import StoreInfo from './profileComponents/StoreInfo';
import CreateAuction from './profileComponents/CreateAuction';
import AuctionList from "./profileComponents/AuctionList";
import UserDetails from './profileComponents/UserAccount';
import MessageBox from './profileComponents/MessageBox';
import MessagesSection from './profileComponents/MessagesSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faStore, faGavel, faSignOutAlt, faMessage, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import Menu from './menu'
import StartAuction from './profileComponents/startPageAuction';
import "../style/profile.css";

const UserProfile = () => {
  const { idcode } = useParams();
  const [userData, setUserData] = useState(null);
  const [userStore, setUserStore] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('profile');
  const [isAuctionFormOpen, setIsAuctionFormOpen] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});
  const [creationError, setCreationError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [currentChatUser, setCurrentChatUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve menu selection from localStorage
    const savedMenu = localStorage.getItem('selectedMenu');
    if (savedMenu) {
      setSelectedMenu(savedMenu);
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://hi.irtwitch.ir/api/user/${idcode}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();

        if (data.is_blocked) {
          // Log out the user and redirect to login if the account is blocked
          localStorage.removeItem('authData');
          navigate('/login');
          return;
        }

        setUserData(data);
        setEditedUserData(data);
      } catch (error) {
        console.error('Error fetching user data from the server.', error);
      }
    };

    const fetchStore = async () => {
      try {
        const response = await fetch(`https://hi.irtwitch.ir/api/stores/${idcode}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setUserStore(data.store);
      } catch (error) {
        console.error('Error fetching store data from the server.', error);
      }
    };

    const fetchMessages = async () => {
      try {
        const response = await fetch(`https://hi.irtwitch.ir/api/users/comments/${idcode}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();

        const grouped = data.reduce((acc, message) => {
          if (message.senderId !== idcode) {
            if (!acc[message.senderId]) {
              acc[message.senderId] = [];
            }
            acc[message.senderId].push(message);
          }
          return acc;
        }, {});
        setMessages(data);
        setGroupedMessages(grouped);
      } catch (error) {
        console.error('Error fetching messages from the server.', error);
      }
    };

    fetchUserData();
    fetchStore();
    fetchMessages();

    const interval = setInterval(() => {
      fetchUserData(); // Check user data every 30 seconds to detect block status
      fetchMessages();
    }, 5000); // every 5 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [idcode]);

  const handleLogout = () => {
    localStorage.removeItem('authData');
    navigate('/login');
  };

  const handleButtonClick = (menu) => {
    setSelectedMenu(menu);
    localStorage.setItem('selectedMenu', menu); // Save menu selection to localStorage
  };

  const handleProfileEditToggle = () => {
    setIsEditingProfile(!isEditingProfile);
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setEditedUserData({ ...editedUserData, [name]: value });
  };

  const handleSaveProfile = async () => {
    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/update-user/${idcode}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedUserData),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();

      alert('اطلاعات کاربر با موفقیت بروزرسانی شد!');
      setUserData(editedUserData);
      setIsEditingProfile(false);
    } catch (error) {
      setCreationError('خطا در برقراری ارتباط با سرور.');
      console.error('Error saving profile.', error);
    }
  };

  const checkStoreNameAvailability = async (name) => {
    try {
      const response = await fetch(`https://hi.irtwitch.ir/api/stores/by-name/${name}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return response.ok;
    } catch (error) {
      console.error('Error checking store name availability.', error);
      return null;
    }
  };

  const openChat = (user) => {
    setCurrentChatUser(user);
  };

  return (
    <div>
      <Menu />
      <div className="menuitem">
        <div className="menuProfile">
          <button
            onClick={() => handleButtonClick('profile')}
            className={selectedMenu === 'profile' ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faUser} />
            <span className="menuText">حساب کاربری</span>
          </button>

          <button
            onClick={() => handleButtonClick('store')}
            className={selectedMenu === 'store' ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faStore} />
            <span className="menuText">فروشگاه</span>
          </button>

          <button
            onClick={() => handleButtonClick('auction')}
            className={selectedMenu === 'auction' ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faGavel} />
            <span className="menuText">ایجاد مزایده</span>
          </button>
          <button
            onClick={() => handleButtonClick('start_auction')}
            className={selectedMenu === 'start_auction' ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faBalanceScale} />
            <span className="menuText">شروع مزایده</span>
          </button>
          <button
            onClick={() => handleButtonClick('messages')}
            className={selectedMenu === 'messages' ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faMessage} />
            <span className="menuText">پیام‌ها</span>
          </button>

          <div
            style={{ height: "44px" }}
            className='btn btn-danger'
            onClick={handleLogout}
          >
            <FontAwesomeIcon style={{ position: "relative", top: "7px" }} icon={faSignOutAlt} />
          </div>
        </div>

        <div className="detailsProfile">
          {selectedMenu === 'profile' && (
            <UserDetails
              userData={userData}
              isEditingProfile={isEditingProfile}
              editedUserData={editedUserData}
              handleProfileChange={handleProfileChange}
              handleProfileEditToggle={handleProfileEditToggle}
              handleSaveProfile={handleSaveProfile}
              creationError={creationError}
            />
          )}

          {selectedMenu === 'store' && (
            <StoreInfo
              idcode={idcode}
              userStore={userStore}
              setUserStore={setUserStore}
              handleDeleteStore={async () => {
                if (!userStore) {
                  alert('فروشگاهی برای حذف وجود ندارد.');
                  return;
                }

                try {
                  const response = await fetch('https://hi.irtwitch.ir/api/stores/delete-store', {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ idcode, storeName: userStore.storeName }),
                  });
                  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                  const data = await response.json();

                  alert('فروشگاه با موفقیت حذف شد!');
                  setUserStore(null);
                } catch (error) {
                  alert('خطا در برقراری ارتباط با سرور.');
                  console.error('Error deleting store.', error);
                }
              }}
              handleCreateStore={async (storeName) => {
                if (!storeName) {
                  alert('لطفاً نام فروشگاه را وارد کنید.');
                  return;
                }

                const isStoreNameTaken = await checkStoreNameAvailability(storeName.toLowerCase().replace(/\s+/g, '-'));

                if (isStoreNameTaken) {
                  alert('نام فروشگاه تکراری است. لطفاً نام دیگری انتخاب کنید.');
                  return;
                }

                try {
                  const response = await fetch('https://hi.irtwitch.ir/api/stores/create-store', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ idcode, storeName }),
                  });
                  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                  const data = await response.json();

                  alert('فروشگاه با موفقیت ایجاد شد!');
                  setUserStore({ storeName });
                  setSelectedMenu('profile');
                } catch (error) {
                  alert('خطا در برقراری ارتباط با سرور.');
                  console.error('Error creating store.', error);
                }
              }}
              checkStoreNameAvailability={checkStoreNameAvailability}
            />
          )}

          {selectedMenu === 'auction' && (
            <div>
              <button className="btn btn-primary" onClick={() => setIsAuctionFormOpen(true)}>
                ایجاد مزایده جدید
              </button>

              {isAuctionFormOpen && (
                <div>
                  <h2>ایجاد مزایده</h2>
                  <CreateAuction idCodeUser={idcode} onClose={() => setIsAuctionFormOpen(false)} />
                </div>
              )}

              <AuctionList idCodeUser={idcode} />
            </div>
          )}

          {selectedMenu === 'start_auction' && (
            <StartAuction idCodeUser={idcode} />
          )}

          {selectedMenu === 'messages' && (
            <MessagesSection
              groupedMessages={groupedMessages}
              openChat={openChat}
            />
          )}
        </div>
      </div>

      {currentChatUser && (
        <MessageBox
          storeData={userData} // ارسال اطلاعات کاربر به MessageBox
          messages={messages.filter(msg => msg.senderId === currentChatUser.id || msg.receiverId === currentChatUser.id)}
          onSendMessage={async (message) => {
            try {
              const response = await fetch('https://hi.irtwitch.ir/api/users/comment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  idUserComment: currentChatUser.id,
                  comment: message,
                  username: idcode
                }),
              });

              if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
              const result = await response.json();

              setMessages(prevMessages => [
                ...prevMessages,
                {
                  username: idcode,
                  comment: message,
                  timestamp: new Date().toISOString(),
                  senderId: idcode,
                  receiverId: currentChatUser.id
                }
              ]);
            } catch (error) {
              alert('خطا در ارسال پیام.');
              console.error('Error sending message.', error);
            }
          }}
          onClose={() => setCurrentChatUser(null)}
        />
      )}
    </div>
  );
};

export default UserProfile;
