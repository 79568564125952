import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Auctionstartpage.css';
import EndAuctionButton from './AuctionstartComponents/EndAuctionButton';
import ToggleCommentsButton from './AuctionstartComponents/ToggleCommentsButton';
import ToggleBidsButton from './AuctionstartComponents/ToggleBidsButton';
import ToggleBidsStatusButton from './AuctionstartComponents/ToggleBidsStatusButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-jalaali';

// تابعی برای تبدیل اعداد فارسی به انگلیسی
const convertPersianNumbersToEnglish = (str) => {
  const persianToEnglish = {
    '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9'
  };
  return str.replace(/[۰-۹]/g, char => persianToEnglish[char]);
};

// تابعی برای فرمت کردن قیمت به تومان با جدا کردن هزارگان
const formatPrice = (price) => {
  if (!price || isNaN(price)) return '';
  const number = Number(price); // تبدیل به عدد
  return number.toLocaleString() + ' تومان'; // فرمت هزارگان و اضافه کردن "تومان"
};

const Auctionstartpage = () => {
  const { id } = useParams(); // دریافت شناسه مزایده از URL
  const [auction, setAuction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [auctionEnded, setAuctionEnded] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(true);
  const [newBid, setNewBid] = useState(''); // وضعیت برای پیشنهاد قیمت
  const [showBids, setShowBids] = useState(false); // وضعیت برای نمایش لیست پیشنهادات قیمت
  const [bidsClosed, setBidsClosed] = useState(false); // وضعیت برای بستن پیشنهادات قیمت
  const [error, setError] = useState(null); // وضعیت برای نمایش پیام خطا
  const navigate = useNavigate();
  const [idCodeUser, setIdCodeUser] = useState(null);
  const [userDetails, setUserDetails] = useState({}); // ذخیره اطلاعات کاربران
  const [liveStarted, setLiveStarted] = useState(false); // وضعیت برای نمایش مکعب لایو

  const toggleLive = () => {
    setLiveStarted(prevState => !prevState);
  };

  useEffect(() => {
    const authData = localStorage.getItem('authData');
    if (authData) {
      try {
        const parsedData = JSON.parse(authData);
        setUser(parsedData);
        setIdCodeUser(parsedData.idcode);
      } catch (error) {
        console.error('خطا در تجزیه داده‌های احراز هویت:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchAuction = async () => {
      try {
        const response = await axios.get(`https://hi.irtwitch.ir/api/auctions/${id}`);
        const activeAuction = response.data;

        if (!activeAuction) {
          setError('چنین مزایده‌ای وجود ندارد.');
          setLoading(false);
          return;
        }

        if (!activeAuction.is_start) {
          setError('هنوز این مزایده شروع نشده است.');
          setLoading(false);
          return;
        }

        setAuction(activeAuction);
        setComments(activeAuction.comments || []);
        setShowComments(!activeAuction.commentsClosed);
        setShowBids(activeAuction.showBids); // دریافت وضعیت نمایش پیشنهادات قیمت از سرور
        setBidsClosed(activeAuction.bidsClosed); // دریافت وضعیت بستن پیشنهادات قیمت از سرور
        setLoading(false);
      } catch (error) {
        console.error('خطا در بارگذاری مزایده:', error);
        setLoading(false);
      }
    };

    fetchAuction();

    const interval = setInterval(async () => {
      try {
        const response = await axios.get(`https://hi.irtwitch.ir/api/auctions/${id}`);
        const activeAuction = response.data;
        if (activeAuction) {
          setAuction(activeAuction);
          setComments(activeAuction.comments || []);
          setShowComments(!activeAuction.commentsClosed);
          setShowBids(activeAuction.showBids); // بروزرسانی وضعیت نمایش پیشنهادات قیمت
          setBidsClosed(activeAuction.bidsClosed); // بروزرسانی وضعیت بستن پیشنهادات قیمت
          if (!activeAuction.is_start) {
            setAuctionEnded(true);
            clearInterval(interval);
            navigate('/');
          }
        }
      } catch (error) {
        console.error('خطا در بررسی وضعیت مزایده:', error);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [id, navigate, user]);

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`https://hi.irtwitch.ir/api/user/${userId}`);
      return response.data; // فرض می‌کنیم که response.data شامل نام و نام خانوادگی کاربر است
    } catch (error) {
      console.error('خطا در دریافت اطلاعات کاربر:', error);
      return null;
    }
  };

  const getUserName = async (userId) => {
    if (userDetails[userId]) {
      return userDetails[userId]; // اگر اطلاعات کاربر قبلاً دریافت شده باشد، آن را برمی‌گرداند
    }

    const userData = await fetchUserDetails(userId);
    if (userData) {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [userId]: `${userData.name} ${userData.family}` // فرض می‌کنیم userData شامل firstName و lastName است
      }));
      return `${userData.name} ${userData.family}`;
    }
    else {
      return 'کاربر ناشناس';
    }
  };

  useEffect(() => {
    (async () => {
      for (const bid of auction?.bids || []) {
        if (!userDetails[bid.userId]) {
          await getUserName(bid.userId);
        }
      }

      for (const comment of comments || []) {
        if (!userDetails[comment.username]) {  // فرض می‌کنیم username حاوی userId باشد
          await getUserName(comment.username);
        }
      }
    })();
  }, [auction, comments]);


  const submitComment = async () => {
    if (newComment.trim()) {
      try {
        await axios.post(
          `https://hi.irtwitch.ir/api/auctions/${id}/comment`,
          {
            username: user ? user.idcode : 'Anonymous',
            message: newComment
          }
        );
        setNewComment('');
        const response = await axios.get(`https://hi.irtwitch.ir/api/auctions/${id}`);
        const updatedAuction = response.data;
        setComments(updatedAuction ? updatedAuction.comments : []);
      } catch (error) {
        console.error('خطا در ارسال نظر:', error);
        alert('خطا در ارسال نظر');
      }
    }
  };

  const handleBidChange = (e) => {
    const value = e.target.value;
    const englishNumber = convertPersianNumbersToEnglish(value);
    setNewBid(englishNumber.replace(/[^0-9]/g, '')); // فقط اعداد را نگه می‌دارد
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setNewBid(prevBid => prevBid.slice(0, -1));
    }
  };

  const submitBid = async () => {
    if (newBid.trim() && !isNaN(newBid)) {
      try {
        await axios.post(
          `https://hi.irtwitch.ir/api/auctions/${id}/bid`,
          {
            userId: user ? user.idcode : 'Anonymous',
            bidAmount: newBid
          }
        );
        setNewBid('');
        const response = await axios.get(`https://hi.irtwitch.ir/api/auctions/${id}`);
        const updatedAuction = response.data;
        setAuction(updatedAuction);
      } catch (error) {
        console.error('خطا در ارسال پیشنهاد:', error);
        alert('خطا در ارسال پیشنهاد');
      }
    }
  };

  const declareWinner = async (userId, bidAmount) => {
    try {
      await axios.put(
        `https://hi.irtwitch.ir/api/auctions/${id}/update-winner`,
        {
          winnerId: userId,
          bidAmount: bidAmount,
          winnerName: userDetails[userId]?.split(' ')[0] || 'نام کاربر',
          winnerSurname: userDetails[userId]?.split(' ')[1] || 'نام خانوادگی کاربر'
        }
      );
      const response = await axios.get(`https://hi.irtwitch.ir/api/auctions/${id}`);
      const updatedAuction = response.data;
      setAuction(updatedAuction);
      alert(`کاربر ${userDetails[userId]} با مبلغ ${formatPrice(bidAmount)} برنده شد!`);
    } catch (error) {
      console.error('خطا در اعلام برنده:', error);
      alert('خطا در اعلام برنده');
    }
  };

  const removeWinner = async () => {
    try {
      await axios.put(
        `https://hi.irtwitch.ir/api/auctions/${id}/remove-winner`
      );
      const response = await axios.get(`https://hi.irtwitch.ir/api/auctions/${id}`);
      const updatedAuction = response.data;
      setAuction(updatedAuction);
      alert('برنده حذف شد.');
    } catch (error) {
      console.error('خطا در حذف برنده:', error);
      alert('خطا در حذف برنده');
    }
  };

  const deleteComments = async () => {
    try {
      await axios.delete(`https://hi.irtwitch.ir/api/auctions/${id}/comments`);
      setComments([]); // Clear the comments in the UI after deletion
      alert('تاریخچه کامنت‌ها با موفقیت حذف شد.');
    } catch (error) {
      console.error('خطا در حذف تاریخچه کامنت‌ها:', error);
      alert('خطا در حذف تاریخچه کامنت‌ها');
    }
  };

  const deleteBids = async () => {
    try {
      await axios.delete(`https://hi.irtwitch.ir/api/auctions/${id}/bids`);
      setAuction(prevAuction => ({
        ...prevAuction,
        bids: []
      })); // Clear the bids in the UI after deletion
      alert('تاریخچه پیشنهادات با موفقیت حذف شد.');
    } catch (error) {
      console.error('خطا در حذف تاریخچه پیشنهادات:', error);
      alert('خطا در حذف تاریخچه پیشنهادات');
    }
  };

  const formatDateToJalali = (dateString) => {
    return moment(dateString).locale('fa').format('jYYYY/jM/jD - HH:mm:ss');
  };

  if (!user) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger">
          <p>برای مشاهده مزایده باید ابتدا <NavLink to="/login">وارد شوید</NavLink> یا <NavLink to="/register">ثبت نام کنید</NavLink>.</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger">
          <p>{error}</p>
          <p>{error === 'چنین مزایده‌ای وجود ندارد.' ? 'برای مشاهده دیگر مزایده‌ها، لطفاً به <NavLink to="/">صفحه اصلی</NavLink> بروید.' : ''}</p>
          {!user.idcode && (
            <p>
              برای استفاده از این ویژگی، لطفاً <NavLink to="/login">وارد شوید</NavLink>.
            </p>
          )}
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>در حال بارگذاری...</div>;
  }

  if (!auction) {
    return <div>چنین مزایده‌ای وجود ندارد یا هنوز شروع نشده است.</div>;
  }

  const isAdmin = user && auction.idCodeUser === idCodeUser;

  // رتبه‌بندی پیشنهادات بر اساس بالاترین قیمت
  const sortedBids = (auction.bids || []).sort((a, b) => b.bidAmount - a.bidAmount);

  return (
    <div className="containerA mt-4">
      <div className="menubtnflex">
        {isAdmin && (
          <div className="menumaneg" style={{ overflow: "scroll" }}>
            {/* Existing buttons */}

            <EndAuctionButton
              auction={auction}
              user={user}
              navigate={navigate}
              setAuctionEnded={setAuctionEnded}
            />
            <ToggleCommentsButton
              auction={auction}
              user={user}
              idCodeUser={idCodeUser}
              showComments={showComments}
              setShowComments={setShowComments}
            />
            <ToggleBidsButton
              auction={auction}
              user={user}
              idCodeUser={idCodeUser}
              showBids={showBids}
              setShowBids={setShowBids}
            />
            <ToggleBidsStatusButton
              auction={auction}
              user={user}
              idCodeUser={idCodeUser}
              bidsClosed={bidsClosed}
              setBidsClosed={setBidsClosed}
            />

            {/* New delete buttons */}
            <button
              className="btn btn-danger mt-2"
              onClick={deleteComments}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> حذف تاریخچه کامنت‌ها
            </button>
            <button
              className="btn btn-danger mt-2"
              onClick={deleteBids}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> حذف تاریخچه پیشنهادات
            </button>
            <button className="btn btn-danger mt-2" onClick={toggleLive}>
              {liveStarted ? 'پایان لایو' : 'شروع لایو'}
            </button>

          </div>
        )}
        <div className="dataAuction">
          <div className="modalimages">
            {Array.isArray(auction.images) ? (
              auction.images.map((image, index) => (
                <img
                  key={index}
                  src={`https://hi.irtwitch.ir${image}`}
                  alt={`تصویر ${index + 1} از ${auction.title}`}
                  className="img-thumbnail"
                  style={{ margin: "5px", width: "200px" }}
                />
              ))
            ) : (
              <img
                src={`https://hi.irtwitch.ir${auction.images}`}
                alt={auction.title}
                className="img-thumbnail"
                style={{ margin: "5px", width: "200px" }}
              />
            )}
          </div>
          <div className="right">
            <p className="mb-4">
              <span>مزایده: {auction.title}</span>
            </p>
            <p>توضیحات: {auction.description}</p>
          </div>
          <div className="left">
            <p>
              حساب کاربری: <span>{user.idcode}</span>
              {isAdmin ? 'خوش آمدید مدیر' : 'خوش آمدید'}
            </p>
            {
              auction.winner && auction.winner.id ? (
                <div>
                  <p>برنده: {auction.winner.name} {auction.winner.surname}</p>
                  <p>مبلغ پیشنهادی: {auction.winner.bidAmount}</p>
                </div>
              ) : (
                <p>برنده‌ای انتخاب نشده است</p>
              )
            }
          </div>

          {auctionEnded && !isAdmin && (
            <div className="alert alert-info mt-3">
              <p>مزایده به پایان رسید!</p>
            </div>
          )}
        </div>
        <div className="massegandbidsBox">
          {liveStarted && (
            <div className="live-box" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ border: "1px solid #fff", width: "fit-content", borderRadius: "10px" }}>
                <iframe
                  src="https://livekadeh.com/official/mhm"
                  frameborder="0"
                  style={{ width: "300px", height: "300px", borderRadius: "10px" }}>
                </iframe>
              </div>
              <p>لایو تست در حال پخش است</p>
            </div>

          )}

          <div className="top">
            {isAdmin && bidsClosed && (
              <p>کاربران توانایی ارسال پیشنهاد را ندارند

              </p>
            )}
            {isAdmin && !bidsClosed && !auctionEnded && (
              <p>کاربران توانایی ارسال پیشنهاد را دارند

              </p>
            )}
            {!auctionEnded && !isAdmin && !bidsClosed && (

              <div className="bid-form">
                <p>پیشنهاد قیمت جدید شما</p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={formatPrice(newBid)}
                    onChange={handleBidChange}
                    onKeyDown={handleKeyDown}
                    placeholder="مبلغ پیشنهاد"
                    disabled={bidsClosed} // غیر فعال کردن فیلد پیشنهاد در صورت بستن پیشنهادات
                  />
                  <div className="d-flex">
                    <button
                      className="btn btn-success"
                      onClick={submitBid}
                      disabled={bidsClosed} // غیر فعال کردن دکمه ارسال در صورت بستن پیشنهادات
                    >
                      ارسال&nbsp;پیشنهاد
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bottom">
            {showComments && (
              <div className="">
                <div className="message-box">
                  <div className="message-list">
                    {comments.length > 0 ? (
                      comments.map((comment, index) => (
                        <div
                          key={index}
                          className={`message ${comment.username === auction.idCodeUser
                            ? 'modir'
                            : comment.username === user.idcode
                              ? 'admin'
                              : 'user'
                            }`}
                        >
                          <div className="mb-1 text-secondary">
                            {comment.username === auction.idCodeUser
                              ? <h5 style={{ color: "#ffff" }}>مدیر</h5>
                              : comment.username === user.idcode
                                ? <p style={{ color: "black" }}>شما</p>
                                : userDetails[comment.username] || 'کاربر ناشناس'}
                          </div>
                          <p className="mb-1">{comment.message}</p>
                          <small className="text-muted">{formatDateToJalali(comment.date)}</small>

                        </div>
                      ))
                    ) : (
                      <p align="center" >هنوز نظری ارسال نشده است.</p>
                    )}
                  </div>
                  <div className="comment-form">
                    <textarea
                      className="form-control"
                      rows="3"
                      value={newComment}
                      onChange={e => setNewComment(e.target.value)}
                      placeholder="نظر خود را بنویسید..."
                      disabled={auction.commentsClosed} // غیر فعال کردن فیلد نظر در صورت بسته بودن کامنت‌ها
                    ></textarea>
                    <button
                      className="btn btn-primary m-1"
                      onClick={submitComment}
                      disabled={auction.commentsClosed} // غیر فعال کردن دکمه ارسال نظر در صورت بسته بودن کامنت‌ها
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showBids && (
              <div className="bids-list">
                <ul className="list-group">
                  <p>پیشنهادات قیمت</p>
                  {sortedBids.length > 0 ? (
                    sortedBids.map((bid, index) => (
                      <li
                        key={index}
                        className={`list-group-item ${auction.winner && (auction.winner.id === bid.userId) && (auction.winner.bidAmount === bid.bidAmount) ? 'winner' : ''}`}
                      >
                        <div>
                          <strong>کاربر: </strong> {userDetails[bid.userId] ? (
                            userDetails[bid.userId]
                          ) : (
                            <span>ناشناس</span>
                          )}<br />
                          <strong>مبلغ پیشنهاد: </strong>{formatPrice(bid.bidAmount)} <br />
                          <small className="text-muted">{formatDateToJalali(bid.date)}</small>
                        </div>
                        <div style={{ display: "block" }}>
                          <p style={{ color: "black" }}>{auction.winner && (auction.winner.id === bid.userId) && (auction.winner.bidAmount === bid.bidAmount) ? 'برنده مزایده' : ''}</p>

                          {isAdmin && !auctionEnded && (
                            <>
                              {auction.winner && auction.winner.id === bid.userId && auction.winner.bidAmount === bid.bidAmount ? (
                                <button
                                  className="btn btn-danger btn-sm float-end"
                                  onClick={removeWinner}
                                >
                                  حذف&nbsp;برنده
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-sm float-end"
                                  onClick={() => declareWinner(bid.userId, bid.bidAmount)}
                                >
                                  {auction.winner ? <p style={{ margin: "0" }}>تغییر&nbsp;برنده</p> : <p style={{ margin: "0" }}>اعلام&nbsp;برنده</p>}
                                </button>
                              )}
                            </>
                          )}
                        </div>

                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">پیشنهادی ثبت نشده است.</li>
                  )}
                </ul>
              </div>
            )}


          </div>
        </div>
      </div>
    </div>
  );
};

export default Auctionstartpage;
