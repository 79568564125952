// PartOne.js
import React from 'react';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faConciergeBell, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BackgroundSwitcher from './BackgroundSwitcher';
import userinterface from "../style/image/user-interface.png";
import '../style/header.css';

const PartOne = () => {
  return (
    <div className="part1">
      <div className="part1part"></div>
      <div className="part1part middle">
        <nav className="menu">
          <ul>
            <li>
              <BackgroundSwitcher />
            </li>
            <ul>
              <li>
                <NavLink to="/" className="menuoptions">
                  <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
                  <span className="text">خانه</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="#about" className="menuoptions">
                  <span className="icon"><FontAwesomeIcon icon={faInfoCircle} /></span>
                  <span className="text">درباره&nbsp;ما</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="#services" className="menuoptions">
                  <span className="icon"><FontAwesomeIcon icon={faConciergeBell} /></span>
                  <span className="text">سرویس&nbsp;ها</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="#contact" className="menuoptions">
                  <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                  <span className="text">ارتباط&nbsp;با&nbsp;ما</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={'/login'}>
                  <img
                    src={userinterface}
                    className="userinterface_icon"
                    width={"25px"}
                    alt=""
                    style={{ cursor: "pointer", transition: "all 1s ease", display: "inline-block" }}
                  />
                </NavLink>
              </li>
            </ul>
          </ul>
        </nav>
      </div>
      <div className="part1part userinterface_text">
        <NavLink to="/login" style={{ textDecoration: 'none', color: "white" }}>
        </NavLink>
      </div>
    </div>
  );
};

export default PartOne;
