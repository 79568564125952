import React from "react";
import ReactDOM from "react-dom/client"; // توجه به مسیر جدید
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./Components/UserContext";

// ایجاد ریشه جدید
const root = ReactDOM.createRoot(document.getElementById("root"));

// رندر کردن اپلیکیشن
root.render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>
);
